import { useHistory } from 'react-router';

import {
  CheckboxRound,
  Divider,
  MenuItem,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { MessagesResponse } from '@api/messaging';
import {
  toggleStatusFilter,
  updatedDueFilter,
  updatedFilterByHub,
  updatedHubFilter,
} from '@pages/action-items/modal';

import styles from './styles.module.css';

type Props = {
  actions?: number;
  messages?: MessagesResponse;
};

export const Overview = ({ actions, messages }: Props) => {
  const history = useHistory();
  return (
    <>
      <li className={styles.item}>
        <div className={styles.card}>
          <div className={styles.header}>
            <TypographyPoppins
              className={styles.color}
              type="title"
              titleSize="M"
            >
              Overview
            </TypographyPoppins>
          </div>
          <div className={styles.horizontal}>
            <Divider className={styles.divider} type="long-line" />
          </div>
          {(actions && actions > 0) ||
          (messages && messages.unread_count > 0) ? (
            <ul className={styles.actionItems}>
              {actions && actions > 0 ? (
                <MenuItem
                  className={styles.actionItem}
                  classNameLeadingIcon={styles.icon}
                  leadingIcon={<CheckboxRound fill="none" size={24} />}
                  onClickContent={() => {
                    updatedDueFilter('');
                    updatedFilterByHub('all');
                    updatedHubFilter(null);
                    toggleStatusFilter('open');
                    history.push('/action-items');
                  }}
                >
                  <TypographyPoppins
                    className={styles.color}
                    type="body"
                    bodySize="M"
                  >
                    <span className={styles.number}>{actions}</span>open action
                    item{actions > 1 ? 's' : ''}
                  </TypographyPoppins>
                </MenuItem>
              ) : null}
              {messages && messages.unread_count > 0 ? (
                <MenuItem
                  className={styles.actionItem}
                  classNameLeadingIcon={styles.icon}
                  leadingIcon={<Icon name="sprite/paper-plane" size={24} />}
                  onClickContent={() => {
                    history.push(`/messages/${messages.results[0].hub?.id}`);
                  }}
                >
                  <TypographyPoppins
                    className={styles.color}
                    type="body"
                    bodySize="M"
                  >
                    <span className={styles.number}>
                      {messages.unread_count}
                    </span>
                    unread message{messages.unread_count > 1 ? 's' : ''}
                  </TypographyPoppins>
                </MenuItem>
              ) : null}
            </ul>
          ) : (
            <div className={styles.emptyState}>
              <Icon name="sprite/bunting-soft-colored" size={80} />
              <TypographyPoppins
                className={styles.content}
                type="body"
                bodySize="S"
              >
                Everything is looking good—
                <br />
                nothing for you follow up on
              </TypographyPoppins>
            </div>
          )}
        </div>
      </li>
    </>
  );
};
