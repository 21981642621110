import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

import { ColorSwatch } from '@api/index';

export const colorCodesDialogOpened = createEvent();
export const colorCodesDialogClosed = createEvent();

export const swatchIdSelected = createEvent<string>();
export const swatchIdCleared = createEvent();

// Activation of existing theme updating
export const exisgingThemeUpdatingEnabled = createEvent();
export const existingThemeUpdatingCompleted = createEvent();

export const $isExistingThemeUpdating = createStore(false);

$isExistingThemeUpdating
  .on(exisgingThemeUpdatingEnabled, () => true)
  .reset(existingThemeUpdatingCompleted);

// Local state for updating a existing theme on the client side
export const themeUpdatingStarted = createEvent<{
  theme: string;
  swatches: ColorSwatch[];
}>();

export const existingThemePlaceholderCleared = createEvent();

export const $existingThemePlaceholder = createStore<
  { theme: string; swatches: ColorSwatch[] }[]
>([{ theme: '', swatches: [] }]);

$existingThemePlaceholder.reset(existingThemePlaceholderCleared);

sample({
  clock: themeUpdatingStarted,
  fn: ({ theme, swatches }) => [
    {
      theme,
      swatches: [
        ...swatches,
        ...Array.from({ length: 5 - swatches.length }, (_, i) => ({
          hex_color: '',
          hsl_color: '',
          hsv_color: '',
          id: `empty-${swatches.length + i + 1}`,
          order: swatches.length + i + 1,
          rgb_color: '',
          theme,
        })),
      ],
    },
  ],
  target: $existingThemePlaceholder,
});

// Updating color swatches
export const swatchUpdated = createEvent<{
  order: number;
  rgb_color: string;
  hex_color: string;
}>();

sample({
  clock: swatchUpdated,
  source: $existingThemePlaceholder,
  fn: (state, { order, hex_color, rgb_color }) =>
    state.map((item) => ({
      ...item,
      swatches: item.swatches.map((swatch) =>
        swatch.order === order ? { ...swatch, hex_color, rgb_color } : swatch,
      ),
    })),
  target: $existingThemePlaceholder,
});

// Removing color swatches
export const swatchCleared = createEvent<number>();

sample({
  clock: swatchCleared,
  source: $existingThemePlaceholder,
  fn: (state, order) =>
    state.map((item) => ({
      ...item,
      swatches: item.swatches.map((swatch) =>
        swatch.order === order
          ? { ...swatch, hex_color: '', rgb_color: '' }
          : swatch,
      ),
    })),
  target: $existingThemePlaceholder,
});

export const $currentSwatches = createStore<
  { theme: string; swatches: ColorSwatch[] }[]
>([{ theme: '', swatches: [] }]);

sample({
  clock: $existingThemePlaceholder,
  fn: (items) =>
    items.map((item) => ({
      ...item,
      swatches: item.swatches.filter((swatch) => swatch.hex_color),
    })),
  target: $currentSwatches,
});

// Color codes
export const $isShowColorCodesDialog = createStore(false);

$isShowColorCodesDialog
  .on(colorCodesDialogOpened, () => true)
  .reset(colorCodesDialogClosed);

sample({
  clock: colorCodesDialogClosed,
  target: swatchIdCleared,
});

// Active swatch
export const $swatchId = createStore('');

$swatchId.on(swatchIdSelected, (_, id) => id).reset(swatchIdCleared);

sample({
  clock: $existingThemePlaceholder,
  fn: (items) => {
    const themes = items.map((theme) => ({
      ...theme,
      swatches: theme.swatches.filter((swatch) => swatch.hex_color),
    }));

    return themes[0].swatches[0].id;
  },
  target: $swatchId,
});

hotkey({
  key: 'Escape',
  filter: $isShowColorCodesDialog,
  target: colorCodesDialogClosed,
});
