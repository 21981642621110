import { ChangeEvent, KeyboardEvent, useRef } from 'react';

import { useUnit } from 'effector-react';

import { BoardResponse } from '@api/boards';
import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import {
  $boardDescription,
  boardDescriptionEntered,
} from '@src/entities/dialogs/board/model';

import styles from './styles.module.css';

type Props = {
  board: BoardResponse;
};

export const Description = ({ board }: Props) => {
  const description = useUnit($boardDescription);

  const ref = useRef<HTMLDivElement>(null);

  const { editBoard } = useBoards({});

  const editDescription = () => {
    if (description.trim() !== board.description) {
      editBoard({ boardId: board.id, description });
    }
  };

  const onInput = (e: ChangeEvent<HTMLDivElement>) => {
    boardDescriptionEntered(e.target.innerText);
  };

  const onBlur = () => {
    if (ref.current) {
      boardDescriptionEntered(ref.current.innerText);
      editDescription();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && ref.current) {
      boardDescriptionEntered(ref.current.innerText);
      ref.current.blur();
    }
  };

  return (
    <div
      className={styles.description}
      ref={ref}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onInput={onInput}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
    >
      {board.description}
    </div>
  );
};
