import { useUnit } from 'effector-react';

import { Tab } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { $selectedTab, changedVaiTabs, VaiTab } from '../../model';

import styles from './styles.module.css';

export const VaiTabs = () => {
  const [selectedTab] = useUnit([$selectedTab]);

  const changeTab = (tab: VaiTab) => changedVaiTabs(tab);
  return (
    <div className={styles.tabs}>
      <Tab
        isSelected={selectedTab === 'chat'}
        className={styles.tab}
        type="icon on top + label"
        icon={<Icon name="sprite/chat-left" color="var(--color-wolfe-20)" />}
        onClickTab={() => changeTab('chat')}
      >
        Chat
      </Tab>
      <Tab
        isSelected={selectedTab === 'personalize'}
        className={styles.tab}
        type="icon on top + label"
        icon={<Icon name="sprite/personalize" color="var(--color-wolfe-20)" />}
        onClickTab={() => changeTab('personalize')}
      >
        Personalize
      </Tab>
      <Tab
        isSelected={selectedTab === 'aesthetiq'}
        className={styles.tab}
        type="icon on top + label"
        icon={<Icon name="sprite/vai-style" color="var(--color-wolfe-20)" />}
        onClickTab={() => changeTab('aesthetiq')}
      >
        AesthetIQ
      </Tab>
    </div>
  );
};
