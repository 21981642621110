import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  generateVaiEmailActionItems,
  generateVaiEmailResponse,
  generateVaiEmailSummary,
  getMutationStatus,
  InfiniteConversationResponse,
  sendMessageToConversation,
  toggleMessagePin,
  triggerVaiHubStatusCheck,
  triggerVaiThreadStatusCheck,
  UserAction,
  VaiPossibleQuestion,
} from '@api/vai';
import { vaiMutationKeys } from '@src/shared/constants/mutation-keys';
import {
  vaiConversationsKeys,
  vaiGeneratedActionKeys,
  vaiPinnedMessageKeys,
} from '@src/shared/constants/query-keys';
import {
  InfiniteData,
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { $isPolling, endedPolling, startedPolling } from './model';

export const useVaiActions = ({
  optionalHubId,
}: {
  optionalHubId?: string;
}) => {
  const [isPolling] = useUnit([$isPolling]);
  const queryClient = useQueryClient();
  const hubId = optionalHubId ?? '';

  const invalidateHubConversationQuery = () => {
    queryClient.invalidateQueries({
      queryKey: vaiConversationsKeys.all,
    });
    queryClient.invalidateQueries({
      queryKey: vaiGeneratedActionKeys.all,
    });
  };

  const generateHubSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateHubSummary(hubId),
    mutationFn: ({ hubId }: { hubId: string }) =>
      triggerVaiHubStatusCheck(hubId),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
  });

  const generateThreadSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateThreadSummary(hubId),
    mutationFn: ({ threadId }: { hubId: string; threadId: string }) =>
      triggerVaiThreadStatusCheck(threadId),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
  });

  const generateEmailSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateEmailSummary(hubId),
    mutationFn: ({ messageId }: { hubId: string; messageId: string }) =>
      generateVaiEmailSummary(messageId),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
  });

  const generateEmailActionItemsMutation = useMutation({
    mutationKey: vaiMutationKeys.generateEmailActionItems(hubId),
    mutationFn: ({ messageId }: { messageId: string; hubId: string }) =>
      generateVaiEmailActionItems(messageId),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
  });

  const generateDraftEmailResponseMutation = useMutation({
    mutationKey: vaiMutationKeys.generateDraftEmailResponse(hubId),
    mutationFn: ({
      messageId,
      tone,
    }: {
      messageId: string;
      hubId: string;
      tone?: 'CHANGE_TONE_FORMAL' | 'CHANGE_TONE_FRIENDLY';
    }) => generateVaiEmailResponse(messageId, tone),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
  });

  const toggleEmailPinMutation = useMutation({
    mutationKey: vaiMutationKeys.toggleEmailPin(hubId),
    mutationFn: ({
      hubId,
      conversationId,
    }: {
      hubId: string;
      conversationId: string;
    }) => toggleMessagePin(hubId, conversationId),
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: vaiConversationsKeys.hubConversation(hubId),
      });

      const originalConversation = queryClient.getQueryData<
        InfiniteData<InfiniteConversationResponse>
      >(vaiConversationsKeys.hubConversation(hubId));

      if (!originalConversation) return;

      const copiedData = {
        ...originalConversation,
        pages: originalConversation.pages.map((p) => {
          return {
            ...p,
            results: p.results.map((c) => {
              return { ...c };
            }),
          };
        }),
      } satisfies typeof originalConversation;

      // Find the message being toggled and update its pin status
      copiedData.pages = copiedData.pages.map((page) => ({
        ...page,
        results: page.results.map((conversation) => {
          if (conversation.id === variables.conversationId) {
            return {
              ...conversation,
              is_pinned: !conversation.is_pinned,
            };
          }
          return conversation;
        }),
      }));

      // Update the query data with optimistic update
      queryClient.setQueryData(
        vaiConversationsKeys.hubConversation(hubId),
        copiedData,
      );

      return { originalConversation };
    },
    onError: (e, _v, ctx) => {
      console.error(e);
      queryClient.setQueryData(
        vaiConversationsKeys.hubConversation(hubId),
        ctx?.originalConversation,
      );
    },
    onSettled: (_, _e, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
      queryClient.invalidateQueries({
        queryKey: vaiPinnedMessageKeys.all,
      });
    },
  });

  const sendMessageMutation = useMutation({
    mutationKey: vaiMutationKeys.sendMessageToConversation(hubId),
    mutationFn: ({
      hubId,
      ...rest
    }: {
      hubId: string;
      vai_action: VaiPossibleQuestion;
      user_action: UserAction;
      relatedObjectId?: string;
      relatedObjectTypeId?: string;
    }) =>
      sendMessageToConversation({
        hubId,
        related_object_id: rest.relatedObjectId,
        related_object_type_id: rest.relatedObjectTypeId,
        vai_action: rest.vai_action,
        user_action: rest.user_action,
      }),
    onSettled: (data) => {
      if (data && data.vai_pid) {
        beginPolling(data.vai_pid, invalidateHubConversationQuery);
      } else {
        invalidateHubConversationQuery();
      }
    },
    onError: (e) => {
      console.error(e);
    },
  });

  return {
    isPolling,
    generateHubSummaryMutation,
    generateThreadSummaryMutation,
    generateEmailSummaryMutation,
    generateEmailActionItemsMutation,
    generateDraftEmailResponseMutation,
    toggleEmailPinMutation,
    sendMessageMutation,
  };
};

const MAX_RETRIES = 10;

const beginPolling = (vai_pid: string, invalidateQuery: () => void) => {
  // Start polling for if the process is complete.
  let pollInterval = 0;
  let pollId: NodeJS.Timeout = setTimeout(() => {}, 0);
  let retries = 0;

  startedPolling();

  const pollStatus = async () => {
    if (retries === MAX_RETRIES) {
      console.error('Max retry limit reached');
      startedSnack({
        label: 'Request timed out',
      });
      return;
    }

    try {
      const { data: pollResponseStatus } = await getMutationStatus(vai_pid);

      // Success
      if (pollResponseStatus.status === 'succeeded') {
        endedPolling();
        invalidateQuery();
        return;
      }

      // Failed
      if (pollResponseStatus.status === 'failed') {
        endedPolling();
        startedSnack({
          label: 'Something went wrong',
        });
        return;
      }

      // In Progress
      if (pollResponseStatus.status === 'in_progress') {
        retries += 1;
        // Increase interval by 1000ms each time
        pollInterval += 1000;
        // Schedule next poll
        pollId = setTimeout(pollStatus, pollInterval);
        return;
      }
    } catch (error) {
      endedPolling();
      console.error('Error polling status:', error);
      // Clear polling on error
      if (pollId) {
        clearTimeout(pollId);
      }
    }
  };

  // Start initial poll
  pollId = setTimeout(pollStatus, pollInterval);
};

const MINIMUM_TIME_S = 3;

export const useVaiActionProgress = (hubId: string) => {
  // Create artificial return delay so this if triggered will show true for atleast some amount of time to prevent jumping
  const [isDelayed, setIsDelayed] = useState(false);

  const isMutatingHubSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateHubSummary(hubId),
  });

  const isMutatingThreadSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateThreadSummary(hubId),
  });

  const isMutatingEmailSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateEmailSummary(hubId),
  });

  const isMutatingEmailActionItems = useIsMutating({
    mutationKey: vaiMutationKeys.generateEmailActionItems(hubId),
  });

  const isMutatingDraftEmailResponse = useIsMutating({
    mutationKey: vaiMutationKeys.generateDraftEmailResponse(hubId),
  });

  const isMutatingSendMessage = useIsMutating({
    mutationKey: vaiMutationKeys.sendMessageToConversation(hubId),
  });

  const isMutating =
    isMutatingHubSummary > 0 ||
    isMutatingThreadSummary > 0 ||
    isMutatingEmailSummary > 0 ||
    isMutatingEmailActionItems > 0 ||
    isMutatingDraftEmailResponse > 0 ||
    isMutatingSendMessage > 0;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isMutating) {
      setIsDelayed(true);
      timer = setTimeout(() => {
        setIsDelayed(false);
      }, MINIMUM_TIME_S);
    }

    return () => clearTimeout(timer);
  }, [isMutating, setIsDelayed]);

  return isMutating || isDelayed;
};
