import React, {
  RefObject,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Dialog,
  EmptyState,
  Modal,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useKeyboardShortcut } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { getDocs, ResponseDocs } from '@api/docs';
import { useDeleteBDC } from '@pages/FileCard/model/queries/useDeleteBDC';
import {
  $fileId,
  $isFileDeleteDialogOpen,
  fileDeleteDialogClosed,
  fileDeleteDialogOpened,
  fileIdCleared,
  fileTypeCleared,
  fileTypeSelected,
} from '@pages/LibraryPage/model';
import { openedVaiTagPopup } from '@src/entities/popups/ui/VaiTagPopup/model';
import { SelectionToolbar } from '@src/entities/selection-toolbar';
import { ViewThumbnailsSheet } from '@src/entities/sheet';
import {
  $viewThumbnailsSheetOpen,
  viewThumbnailsSheetClosed,
} from '@src/entities/sheet/view-thumbnails/model';
import { ALL_DOCS_QUERY } from '@src/shared/constants/query-names';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useBilling } from '@src/shared/queries/useBilling';
import {
  KEY_DOWN,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP,
  uploadFiles,
} from '@src/shared/utils';
import { openChromeExtension } from '@src/shared/utils/files/open-chrome-extension';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  Block,
  downloadSelectedBlocks,
  downloadSelectedBlocksSingle,
} from '../api';
import { addBlocksToSet, createNewSet } from '../api/blocks';
import { useAllBoards } from '../pages/BoardPage/model/queries/useAllBoards';
import { FilesSection } from './FilesSection';

import styles from './styles.module.css';

type Page = 'library' | 'board';
interface LayoutGridProps {
  // listInfo: any[];
  withAvatar?: boolean;
  createBoard?: boolean;
  // loading?: boolean;
  // loaded?: boolean;
  cleanState?: any;
  // refetch?: any;
  actions?: any;
  customGroupAction?: any;
  hasPermissionEditing?: boolean;
  isOwner?: boolean;
  page?: Page;
  imageBlocks: Block[] | undefined;
  imageBlocksLoading: boolean;
  imageBlockHasNext: boolean | undefined;
  imageBlockFetchNext: () => void;
  designBlocks: Block[] | undefined;
  designBlocksLoading: boolean;
  designBlocksHasNext: boolean | undefined;
  designBlocksFetchNext: () => void;
  paletteBlocks: Block[] | undefined;
  paletteBlocksLoading: boolean;
  paletteBlocksHasNext: boolean | undefined;
  paletteBlocksFetchNext: () => void;
  allDataLoaded: boolean;
  refetchQueries: () => void;
  boardId?: string;
  docs?: ResponseDocs;
}

interface SelectedImage {
  [name: string]: SelectedImageDetails;
}

export interface SelectedImageDetails {
  selected?: boolean;
  id: string;
  imageRef?: any;
  imageInfo: any;
}

export const LayoutGrid = ({
  // listInfo = [],
  // withAvatar,
  createBoard,
  // loading,
  // loaded,
  // cleanState,
  // refetch,
  actions = true,
  hasPermissionEditing = true,
  isOwner = true,
  page,
  designBlocks,
  imageBlocks,
  paletteBlocks,
  designBlocksLoading,
  imageBlocksLoading,
  paletteBlocksLoading,
  imageBlockHasNext,
  imageBlockFetchNext,
  designBlocksHasNext,
  designBlocksFetchNext,
  paletteBlocksHasNext,
  paletteBlocksFetchNext,
  // refetchQueries,
  boardId,
  docs,
}: LayoutGridProps) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [selectedImages, setSelectedImages] = useState<SelectedImage>({});

  // const [hideBrushIcon, setHideBrushIcon] = useState(true);
  const [anyImageActionOpen, setAnyImageActionOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const isFileDeleteDialogOpen = useUnit($isFileDeleteDialogOpen);
  const fileId = useUnit($fileId);
  const viewThumbnailsSheetOpen = useUnit($viewThumbnailsSheetOpen);

  const { board_id } = useParams<any>();

  const boardPage = /^\/board\//;
  const isboardPage = boardPage.test(pathname);

  const { data: allBoards } = useAllBoards();

  const {
    query: { isError, data },
    // syncMutation: { mutateAsync },
  } = useBilling();

  const docsBlockQuery = useQuery({
    queryKey: [ALL_DOCS_QUERY],
    queryFn: () => getDocs({ ordering: '-created_at' }),
    select: transformToFileType,
  });

  const allBlocks = React.useMemo(
    () =>
      imageBlocks && designBlocks && paletteBlocks
        ? [...imageBlocks, ...designBlocks, ...paletteBlocks]
        : [],
    [imageBlocks, designBlocks, paletteBlocks],
  );

  const clearSelectedImage = useCallback(() => {
    setSelectedImages({});
    setSelectAll(false);
  }, []);

  const idDeleted = (item: any) => {
    const deletedObj = Object.keys(selectedImages).reduce(
      (object: any, key: any) => {
        if (key !== item) {
          object[key] = selectedImages[key];
        }
        return object;
      },
      {},
    );
    setSelectedImages(deletedObj);
  };

  const selectImage = useCallback(
    (e: any, imageRef?: any) => {
      let item = '';
      const newselectedImages = { ...selectedImages };

      if (e.target.dataset.item) {
        item = e.target.dataset.item;
      } else {
        item = e.target.id;
      }

      const imageInfo = allBlocks.find((element) => element.id === item);
      newselectedImages[item] = {
        selected: !selectedImages[item]?.selected,
        id: item,
        imageRef,
        imageInfo,
      };
      if (!e.shiftKey) {
        setSelectedImages(newselectedImages);
      }

      if (!newselectedImages[item].selected) {
        delete selectedImages[newselectedImages[item].id];

        setSelectedImages({
          ...selectedImages,
        });
      }
    },
    [selectedImages, allBlocks],
  );

  const actImage = useMemo(() => {
    const selectedarray: SelectedImageDetails[] = [];
    Object.keys(selectedImages).forEach((item) => {
      if (selectedImages[item].selected) {
        selectedarray.push(selectedImages[item]);
      }
    });
    return selectedarray;
  }, [selectedImages]);

  const headline =
    actImage.length === 1 || fileId
      ? 'Delete this file?'
      : 'Delete these files?';

  const description =
    actImage.length === 1 || fileId
      ? 'You will permanently delete this file from your library.'
      : 'You will permanently delete these files from your library.';

  const handleDownload = async (e: SyntheticEvent) => {
    e.stopPropagation();
    const singleSelectedImage = e.currentTarget.getAttribute('data-itemid');
    const blocks = singleSelectedImage
      ? [singleSelectedImage]
      : actImage.map((image) => image.id);

    if (!singleSelectedImage && actImage.length > 1) {
      try {
        startedSnack({
          label: `Downloading images...`,
          close: true,
        });

        await downloadSelectedBlocks({ blocks });

        startedSnack({
          label: `Downloaded images`,
          close: true,
        });
      } catch (error) {
        console.log(error);

        startedSnack({
          label: `Couldn't download images`,
          action: {
            label: 'Try again',
            action: () => {
              downloadSelectedBlocks({ blocks });
            },
          },
          close: true,
        });
      }
    } else {
      await downloadSelectedBlocksSingle(
        singleSelectedImage || actImage[0].id,
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${singleSelectedImage || actImage[0].id}.jpg`;
        link.click();
      });
    }
  };

  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const uploadAction = () => {
    uploadButtonRef.current?.click();
  };

  const onSuccess = () => {
    fileDeleteDialogClosed();
    fileIdCleared();
    clearSelectedImage();
  };

  const { removeBlock } = useDeleteBDC({ onSuccess });

  const [doNotShowVaiTagPopup] = useLocalStorage(
    'do_not_show_vai_tag_popup',
    false,
  );

  const handleUpload = () => {
    if (!allBoards) return;
    //@ts-ignore
    const { files = [] } = uploadButtonRef.current;
    const board = allBoards.find((board) => board.id === board_id);
    uploadFiles(files, board);

    if (files.length >= 3 && !doNotShowVaiTagPopup) {
      openedVaiTagPopup();
    }
  };

  const handleSelectImage = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string,
    imageRef: RefObject<HTMLElement>,
  ) => {
    if (event.shiftKey && actions) {
      history.push({ search, hash: `#/f/${id}` });
    }
    if (!event.shiftKey) {
      selectImage(event, imageRef);
    }
  };

  // const imageInfo = allBlocks.find((element) => element.id);

  // const selectedSingleImage: SelectedImageDetails[] =
  //   Object.keys(selectedSingleImageId).map((id) => ({
  //     id,
  //     imageRef,
  //     imageInfo,
  //   })) || null;

  // const handleDrop = (e: any) => {
  //   e.preventDefault();
  //   setDragAndDrop(false);
  //   const board = allBoards.find((board: any) => board.id === board_id);
  //   uploadFiles(e.dataTransfer.files, board);
  // };

  // const handleDragOver = (e: any) => e.preventDefault();

  // const handleDragEnter = () => {
  //   setDragAndDrop(true);
  // };

  // const handleDragLeave = () => {
  //   setDragAndDrop(false);
  // };

  // const handleOnSelect = (e: any) => {
  //   const newselectedImages = { ...selectedImages };
  //   e.added.forEach((el: any) => {
  //     const { item } = el.dataset;
  //     const imageInfo = listInfo.find((element) => element.id === item);
  //     newselectedImages[item] = {
  //       selected: true,
  //       id: item,
  //       imageInfo,
  //     };
  //   });
  //   if (e.added.length > 0) {
  //     setSelectedImages(newselectedImages);
  //   }
  // };

  const handleClose = () => {
    fileDeleteDialogClosed();
    fileIdCleared();
    fileTypeCleared();
  };

  const handleKeyboardArrows = (event: any) => {
    if (actImage.length === 0) return;
    const currentSelected = actImage[0]?.id;
    const index = allBlocks.findIndex((index) => index.id === currentSelected);

    const selectAndScroll = (tempElemnt: any) => {
      const selectedButton = document.querySelectorAll(
        `[data-item='${tempElemnt.id}']`,
      )[0];

      selectedButton?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      if (actImage.length > 0) {
        const orgObject = {
          [tempElemnt.id]: {
            id: tempElemnt.id,
            selected: true,
            imageInfo: tempElemnt.id,
          },
        };

        setSelectedImages(orgObject);
      }
    };

    //Handle Arrow Up
    if (event.arrowup) {
      const tempElemnt = { ...allBlocks[index - 4] };
      selectAndScroll(tempElemnt);
    }

    //Handle Arrow Down
    if (event.arrowdown) {
      const tempElemnt = { ...allBlocks[index + 4] };
      selectAndScroll(tempElemnt);
    }

    //Handle Arrow Left
    if (event.arrowleft) {
      const tempElemnt = { ...allBlocks[index - 1] };
      selectAndScroll(tempElemnt);
    }

    //Handle Arrow Right
    if (event.arrowright) {
      const tempElemnt = { ...allBlocks[index + 1] };
      selectAndScroll(tempElemnt);
    }
  };

  useKeyboardShortcut(KEY_LEFT, handleKeyboardArrows);
  useKeyboardShortcut(KEY_RIGHT, handleKeyboardArrows);
  useKeyboardShortcut(KEY_UP, handleKeyboardArrows);
  useKeyboardShortcut(KEY_DOWN, handleKeyboardArrows);

  const { mutate: createDesign } = useMutation({
    mutationFn: async () => {
      let response;

      if (page === 'board' && boardId) {
        response = await createNewSet({ name: 'Untitled', boardId });
      } else {
        response = await createNewSet({ name: 'Untitled' });
      }

      await addBlocksToSet(
        response.data.id,
        actImage.map((img) => ({ file: img.id })),
      );

      return response.data.id;
    },
    onSuccess: (variables) => {
      history.push(`/studio/${variables}?generateFirstThumbnail=true`);
      viewThumbnailsSheetClosed();
    },
  });

  const openDeleteDialog = () => {
    fileDeleteDialogOpened();
    if (actImage.length === 1) {
      fileTypeSelected(actImage[0].imageInfo.block_type);
    }
  };

  if (isError) return <p>Error</p>;

  return (
    <>
      {allBlocks?.length > 0 || docsBlockQuery.data?.length > 0 ? (
        <ul className={styles.sectionsList}>
          <li className={styles.section}>
            <FilesSection
              blocks={page === 'board' ? docs?.results : docsBlockQuery.data}
              isLoading={docsBlockQuery.isLoading}
              actions={actions}
              history={history}
              selectedImages={selectedImages}
              onImageClick={() => {}}
              createBoard={createBoard}
              anyImageActionOpen={anyImageActionOpen}
              setAnyImageActionOpen={setAnyImageActionOpen}
              hasPermissionEditing={hasPermissionEditing}
              isOwner={isOwner}
              handleUpload={handleUpload}
              uploadButtonRef={uploadButtonRef}
              uploadAction={uploadAction}
              plan={data?.membershipTierInfo.plan}
              type="Doc"
              size="Large"
              title="Documents"
              fetchNextPage={undefined}
              hasNextPage={false}
              page={page}
              boardId={boardId}
            />
          </li>

          <li className={styles.section}>
            <FilesSection
              blocks={imageBlocks}
              isLoading={imageBlocksLoading}
              actions={actions}
              history={history}
              selectedImages={selectedImages}
              onImageClick={handleSelectImage}
              createBoard={createBoard}
              anyImageActionOpen={anyImageActionOpen}
              setAnyImageActionOpen={setAnyImageActionOpen}
              hasPermissionEditing={hasPermissionEditing}
              isOwner={isOwner}
              handleUpload={handleUpload}
              uploadButtonRef={uploadButtonRef}
              uploadAction={uploadAction}
              plan={data?.membershipTierInfo.plan}
              selectAll={selectAll}
              type="Image"
              size="Large"
              title="Images"
              hasNextPage={!!imageBlockHasNext}
              fetchNextPage={imageBlockFetchNext}
              page={page}
              boardId={boardId}
              downloadSelectedBlocksSingle={downloadSelectedBlocksSingle}
            />
          </li>
          <li className={styles.section}>
            <FilesSection
              blocks={designBlocks}
              isLoading={designBlocksLoading}
              actions={actions}
              history={history}
              selectedImages={selectedImages}
              onImageClick={handleSelectImage}
              createBoard={createBoard}
              anyImageActionOpen={anyImageActionOpen}
              setAnyImageActionOpen={setAnyImageActionOpen}
              hasPermissionEditing={hasPermissionEditing}
              isOwner={isOwner}
              handleUpload={handleUpload}
              uploadButtonRef={uploadButtonRef}
              uploadAction={uploadAction}
              plan={data?.membershipTierInfo.plan}
              selectAll={selectAll}
              type="Design"
              size="Large"
              title="Designs"
              hasNextPage={!!designBlocksHasNext}
              fetchNextPage={designBlocksFetchNext}
              page={page}
              boardId={boardId}
              downloadSelectedBlocksSingle={downloadSelectedBlocksSingle}
            />
          </li>
          <li className={styles.section}>
            <FilesSection
              blocks={paletteBlocks}
              isLoading={paletteBlocksLoading}
              actions={actions}
              history={history}
              selectedImages={selectedImages}
              onImageClick={handleSelectImage}
              createBoard={createBoard}
              anyImageActionOpen={anyImageActionOpen}
              setAnyImageActionOpen={setAnyImageActionOpen}
              hasPermissionEditing={hasPermissionEditing}
              isOwner={isOwner}
              handleUpload={handleUpload}
              uploadButtonRef={uploadButtonRef}
              uploadAction={uploadAction}
              plan={data?.membershipTierInfo.plan}
              selectAll={selectAll}
              type="Palette"
              size="Large"
              title="Palettes"
              hasNextPage={!!paletteBlocksHasNext}
              fetchNextPage={paletteBlocksFetchNext}
              page={page}
              boardId={boardId}
              downloadSelectedBlocksSingle={downloadSelectedBlocksSingle}
            />
          </li>
        </ul>
      ) : (
        <div
          className={cn({
            [styles.container]: isboardPage,
          })}
        >
          <EmptyState
            className={cn(styles.emptyState, {
              [styles.boardPage]: isboardPage,
            })}
            icon={<Icon name="sprite/image-cards-soft-colored" size={80} />}
            content={`No files in here yet. How about 
                     adding some images?`}
            label="Upload from device"
            action={uploadAction}
            secondaryLabel="Install browser extension"
            secondaryAction={openChromeExtension}
          />
          <input
            type="file"
            multiple
            ref={uploadButtonRef}
            className={styles.uploadInput}
            accept="image/png, image/jpeg"
            onChange={handleUpload}
          />
        </div>
      )}

      {actImage.length > 0 && (
        <SelectionToolbar
          items={actImage}
          download={handleDownload}
          remove={openDeleteDialog}
          createDesign={createDesign}
          closeAction={clearSelectedImage}
        />
      )}
      <ViewThumbnailsSheet
        items={actImage}
        isOpen={viewThumbnailsSheetOpen}
        close={viewThumbnailsSheetClosed}
        download={handleDownload}
        idDeleted={idDeleted}
        createDesign={createDesign}
      />

      <Modal handleClose={handleClose} showModal={isFileDeleteDialogOpen}>
        <Dialog
          headline={headline}
          description={description}
          action={() => {
            if (fileId) {
              removeBlock(fileId);
            } else {
              const fileIds = actImage.map((image) => image.id);
              removeBlock(fileIds);
            }
          }}
          actionLabel="Confirm"
          closeAction={handleClose}
        />
      </Modal>
    </>
  );
};

const transformToFileType = (response: any) => {
  const result = response?.results.map((doc: any) => ({
    block_type: 'Doc',
    id: doc.id,
    file: {
      thumbnail_640: '',
      thumbnail_400: '',
      thumbnail_330: '',
      thumbnail_100: '',
      full_size: '',
    },
    name: doc.title,
    board: doc.board,
    hub: doc.hub,
  }));

  return result;
};
