import React, { createContext, useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { useHistory, useParams } from 'react-router-dom';

import {
  AssistChip,
  Divider,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { getDocsOfBoard } from '@api/docs';
import { LibraryActions } from '@components/LibraryActions';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { locationSelected } from '@src/entities/dialogs/board/creation/search/model';
import { $isShowBoardDialog } from '@src/entities/dialogs/board/model';
import { RECENT_BOARDS_KEY } from '@src/shared/constants/variables-local-storage';
import { useRecent } from '@src/shared/hooks/useRecent';
import { showingDemoWarning } from '@src/widgets/onboarding/model';
import { useQuery } from '@tanstack/react-query';

import { Board, getBoard, getInvitees, UserInvitation } from '../../api';
import { PermissionsHub } from '../../api/hubs';
import { createBoardShare, deleteShare, getShare } from '../../api/share';
import { useAppData } from '../../AppContext';
import { DocumentHead } from '../../components/DocumentHead';
import { FileCard } from '../../pages';
import { BoardsStripe } from '../../pages/BoardPage/components/BoardsStripe';
import { Container } from '../../pages/BoardPage/components/Container';
import { Grid } from '../../pages/BoardPage/components/Grid';
import { Title } from '../../pages/BoardPage/components/Title';
import {
  BOARD_QUERY,
  DOCS_OF_BOARD_QUERY,
  MEMBERS_INVITATION_QUERY,
} from '../../shared/constants';
import { Description } from './components/descripton';
import { BoardShareSheet } from './components/ShareSheet';

import styles from './styles.module.css';

export type BoardShare = {
  allow_comments: boolean;
  board: string;
  hub: null;
  id: string;
  is_active: boolean;
  link: string;
  nested_board: [{ is: string; name: string }];
  set: null;
};

interface BoardContextInterface {
  hasPermissionEditing: boolean;
  isOwner: boolean;
}

const Context = createContext<BoardContextInterface>({
  hasPermissionEditing: true,
  isOwner: true,
});

export const BoardPage = () => {
  const [users, setUsers] = useState<UserInvitation[]>([]);
  const [invitation, setInvitation] = useState<UserInvitation>({
    email: '',
  });
  const [permissions, setPermissions] = useState<PermissionsHub[]>([
    'edit',
    'invite',
  ]);
  const [boardShare, setBoardShare] = useState<BoardShare | null>(null);
  const [nestedBoards, setNestedBoards] = useState<string[]>([]);

  const isShowBoardDialog = useUnit($isShowBoardDialog);

  const { board_id } = useParams<{ board_id: string }>();

  const history = useHistory();
  const { user } = useAppData();

  const { data: board } = useQuery({
    queryKey: [BOARD_QUERY, { board_id }],
    queryFn: () => getBoard(board_id),
  });

  const {
    data: invitees,
    isError,
    error,
  } = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, { board_id }],
    queryFn: () => getInvitees(board_id),
  });

  const { data: docs } = useQuery({
    queryKey: [DOCS_OF_BOARD_QUERY, { board: board_id }],
    queryFn: () => getDocsOfBoard({ board: board_id }),
  });

  const hasPermissionEditing =
    invitees &&
    invitees.members
      ?.filter(
        (invitee) => invitee.user && invitee.user.email === user.email,
      )[0]
      ?.permissions.some((permission) => permission === 'edit');

  const isOwner =
    invitees &&
    invitees.members?.filter(
      (invitee) => invitee.user && invitee.user.email === user.email,
    )[0]?.is_owner;

  useRecent({
    key: RECENT_BOARDS_KEY,
    item: {
      id: board_id,
      name: board?.name ?? '',
    },
  });

  useEffect(() => {
    if (isShowBoardDialog && board && board.level !== 2) {
      locationSelected({
        id: board.id,
        name: board.name,
        level: board.level,
        parent: board.parent,
        type: 'board',
      });
    } else if (
      isShowBoardDialog &&
      board &&
      board.parent &&
      board.level === 2
    ) {
      locationSelected({
        id: board.parent.id,
        name: board.parent.name,
        level: board.parent.level,
        parent: board.parent.parent,
        type: 'board',
      });
    }
  }, [board, isShowBoardDialog]);

  const isBoardInHub = board?.hub !== null;

  useEffect(() => {
    if (board && board.children !== null) {
      setNestedBoards(board.children.map((board: Board) => board.id));
    } else {
      setNestedBoards([]);
    }
  }, [board]);

  useEffect(() => {
    const getShareFromId = async (shareId: string) => {
      const boardShare = await getShare(shareId);
      setBoardShare(boardShare);
    };
    if (board && board?.share?.id) {
      getShareFromId(board.share.id);
    } else {
      setBoardShare(null);
    }
  }, [board]);

  useEffect(() => {
    if (!users.length && !invitation.email.length) {
      setPermissions(['edit', 'invite']);
    }
  }, [users.length, invitation.email.length]);

  const shareToWeb = async (published: boolean, allow_comments = true) => {
    if (user.meta?.onboarding?.demoMode) {
      showingDemoWarning();
      return;
    }
    if (published && board) {
      let nestedBoards: string[] = [];
      nestedBoards = board.children?.map((nestedBoard) => nestedBoard.id);
      const share = await createBoardShare(
        board.id,
        allow_comments,
        nestedBoards,
      );
      setBoardShare(share);
    } else {
      if (boardShare) {
        await deleteShare(boardShare.id);
        setBoardShare(null);
      }
    }
  };

  const copyPublicLink = async () => {
    try {
      if (boardShare) {
        await navigator.clipboard.writeText(boardShare.link);
        startedSnack({
          label: 'Copied link',
          close: true,
        });
      }
    } catch (error) {
      startedSnack({
        label: "Oops, could'nt copy this",
        action: {
          label: 'Try again',
          action: () => {
            copyPublicLink();
          },
        },
        close: true,
      });
    }
  };

  if (isError) {
    if (error.message.includes('401')) {
      history.push('/error');
    }
  }

  if (
    !board ||
    typeof hasPermissionEditing === 'undefined' ||
    typeof isOwner === 'undefined'
  )
    return null;

  return (
    <Context.Provider value={{ hasPermissionEditing, isOwner }}>
      <DocumentHead name={board.name} />
      <FileCard page="Board" />
      <div className={styles.boardPage}>
        <Container>
          <Breadcrumbs
            className={styles.breadcrumbs}
            board={board.parent}
            isClickable
          />
          {boardShare?.link ? (
            <div className={styles.shareBanner}>
              <Tooltip
                style={{ minWidth: 'max-content', left: '119px' }}
                parameter={{
                  type: 'plain',
                  description: 'Anyone on the internet with this link can view',
                  hasVisualBoundary: false,
                  position: 'top',
                }}
              >
                <Icon
                  name="sprite/information"
                  size={16}
                  className={styles.infoIcon}
                />
              </Tooltip>
              <TypographyPoppins
                type="body"
                bodySize="M"
                className={styles.text}
              >
                This page is shared to the{' '}
                <a
                  href={boardShare.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  web
                </a>
                .
              </TypographyPoppins>
              <AssistChip
                className={styles.assistChip}
                leadingIcon
                icon={<Icon name="sprite/public" />}
                onClick={copyPublicLink}
                style="outlined"
              >
                Copy public link
              </AssistChip>
            </div>
          ) : null}
          <Title
            board={board}
            shareToWeb={shareToWeb}
            boardShare={boardShare}
          />
          <Description board={board} />
          <Divider type="long-line" className={styles.divider} />
          <BoardsStripe
            boardsThumbnails={board.children}
            parent={board_id}
            level={board.level}
          />
        </Container>
        <Grid
          boardId={board_id}
          hasPermissionEditing={hasPermissionEditing}
          isOwner={isOwner}
          docs={docs}
        />
        <BoardShareSheet
          boardId={board_id}
          boardShare={boardShare}
          setBoardShare={setBoardShare}
          subBoards={board.children ? board.children : []}
          users={users}
          setUsers={setUsers}
          invitation={invitation}
          setInvitation={setInvitation}
          permissions={permissions}
          setPermissions={setPermissions}
          nestedBoards={nestedBoards}
          invitees={invitees}
          boardName={board.name}
          isBoardInHub={isBoardInHub}
        />

        <LibraryActions />
      </div>
    </Context.Provider>
  );
};

export const useBoardContext = () => React.useContext(Context);
