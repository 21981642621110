import {
  Button,
  Spinner,
  VaiColouredPathIcon,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ErrorBoundary } from '@sentry/react';
import {
  Card,
  CardContent,
  CardEmptyContent,
  CardHeader,
} from '@src/entities/cards';
import { useActionConversation } from '@src/widgets/vai-main/hooks/useActionConversation';
import { useConversations } from '@src/widgets/vai-main/hooks/useConversations';
import { openedVaiModalWithHub } from '@src/widgets/vai-main/model';
import { LatestConversation } from '@src/widgets/vai-main/ui/page-cards';

import styles from './styles.module.css';

export const VaiCard = ({
  hubId,
  hubName,
}: {
  hubId: string;
  hubName: string;
}) => {
  const { conversationQuery: conversationQueryVaiMessage } = useConversations({
    // type: 0,
    hub: hubId,
  });

  const actionQuery = useActionConversation({
    hubId,
  });

  if (conversationQueryVaiMessage.isLoading || actionQuery.isLoading) {
    return (
      <Card>
        <CardHeader
          title="Vai"
          icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
        />
        <CardContent>
          <div className={styles.loader}>
            <Spinner />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Vai"
        icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
      />
      {((conversationQueryVaiMessage.data &&
        conversationQueryVaiMessage.data.conversations.length === 0) ||
        !conversationQueryVaiMessage.data) &&
      ((actionQuery.data && actionQuery.data.actions.length === 0) ||
        !actionQuery.data) ? (
        <CardEmptyContent
          icon={<Icon name="sprite/sparkles-vai-colored" size={80} />}
          description={`Vai is your copilot—get
											automated assistance to
											take back your time`}
          button={
            <Button
              label="Talk to Vai"
              onClick={() => {
                openedVaiModalWithHub({
                  id: hubId,
                  name: hubName,
                });
              }}
              type="outlined"
            />
          }
        />
      ) : (
        <CardContent>
          {/* Error boundary to prevent the Conversations from crashing since unsafe parse */}
          <ErrorBoundary
            fallback={<p>Sorry, it looks like something went wrong</p>}
          >
            <LatestConversation
              basePath={`/h/${hubId}`}
              messages={
                conversationQueryVaiMessage.data?.conversations.reverse() ?? []
              }
              actionItems={actionQuery.data?.actions ?? []}
            />
          </ErrorBoundary>
        </CardContent>
      )}
    </Card>
  );
};
