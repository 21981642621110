import { useState } from 'react';

import {
  Divider,
  Dropdown,
  IconButton,
  Item,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { deleteHubModalOpened } from '@src/entities/delete-modals/modal';
import {
  hubDescriptionEntered,
  hubIdSelected,
  hubNameEntered,
  hubRenamingDialogOpened,
  locationEntered,
} from '@src/entities/dialogs/hub/model';
import { Autocomplete } from '@src/widgets/Autocomplete';

import styles from './styles.module.css';

type Props = {
  name: string;
  description: string;
  id: string;
  location?: string;
};

export const Details = ({ name, description, id, location }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/h/${id}`,
      );
      startedSnack({
        label: 'Copied link',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    hubRenamingDialogOpened();
    hubNameEntered(name);
    hubIdSelected(id);

    if (description) {
      hubDescriptionEntered(description);
    }

    if (location) {
      locationEntered(location);
    }
  };

  const deleteHub = () => {
    deleteHubModalOpened({
      hubId: id,
      name,
    });
  };

  const menuItems: Item[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: copyLink,
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      isDivider: true,
      onClick: rename,
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      classNameContent: styles.delete,
      onClick: deleteHub,
    },
  ];

  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <TypographyPoppins className={styles.name} type="title" titleSize="M">
            {name}
          </TypographyPoppins>
          <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
            <Dropdown.Menu
              trigger={
                <IconButton
                  type="unfilled"
                  icon={<Icon name="sprite/3-dot-menu" />}
                  onClick={() => {}}
                  isSelected={isOpenDropdown}
                />
              }
              side="top"
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>

        <div className={styles.details}>
          <div className={styles.description}>
            <Icon className={styles.icon} name="sprite/information" />
            <div
              className={styles.text}
              placeholder={
                description ? '' : 'write a short description of this project'
              }
            >
              {description}
            </div>
          </div>
          <Autocomplete hubId={id} location={location} />
        </div>
      </div>
    </li>
  );
};
