import { openedPlanModal, openedTrialSheet } from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

/**
 *
 * Uses the useBilling hook to check if the trial sheet or if the plan upgrade sheet should open.
 *
 * @function useMembership
 * @description This hook is used to manage the membership of the user. It uses the useBilling hook to fetch the user's billing information.
 * Based on the user's current membership status and trial information, it decides whether to open the trial sheet or the plan upgrade sheet.
 * @returns {Object} An object containing the following properties:
 * - checkoutPro: A function which can be used to upgrade the user's plan to pro.
 * - isReady: A boolean which indicates whether the user's billing information has been loaded.
 */

export const useMembership = () => {
  const { query } = useBilling();

  const checkoutPro = () => {
    if (query.data && query.data?.membershipTierInfo.trialInfo.has_had_trial) {
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planToChange: 'pro',
        planModalMode:
          query.data.membershipTierInfo.plan === 'free'
            ? 'free-pro'
            : 'starter-pro',
      });
    } else {
      openedTrialSheet();
    }
  };

  return {
    billingQuery: query,
    checkoutPro,
    isReady: !query.isLoading,
    isPro: query.isLoading
      ? null
      : query.data?.membershipTierInfo.plan === 'pro' ||
        query.data?.membershipTierInfo.plan === 'trial',
  };
};
