import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { useLocation } from 'react-router';

import { Button } from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { bulkDeleteBlock, getAllCopiesFile } from '@api/blocks';
import { getBoards } from '@api/boards';
import { bulkClipToBoardImages } from '@api/library';
import { useBlock } from '@pages/FileCard/model/queries/useBlock';
import {
  ALL_BOARD_IMAGES_QUERY,
  ALL_FILE_COPIES,
  BOARDS_SUGGESTIONS_QUERY,
  LIBRARY_QUERY,
} from '@src/shared/constants/query-names';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { $searchQuery, searchQueryStarted } from '../model';
import { AllBoardsList } from './AllBoardsList';
import { SearchBoardsList } from './SearchBoardsList';
import { SearchField } from './SearchField';

import styles from './styles.module.css';

export type BoardIdsToCopy = {
  boards: string[];
};

export type BoardIdsToRemove = {
  blocks: string[];
  boards: string[];
};

export const SearchBoards = () => {
  const [boardIdsToCopy, setBoardIdsToCopy] = useState<BoardIdsToCopy>({
    boards: [],
  });
  const [boardIdsToRemove, setBoardIdsToRemove] = useState<BoardIdsToRemove>({
    blocks: [],
    boards: [],
  });
  const [idsSelectedBoards, setIdsSelectedBoards] = useState<Array<string>>([]);

  const searchQuery = useUnit($searchQuery);

  const inputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const location = useLocation();
  const id = location.hash.replace('#/f/', '');
  const { data: block } = useBlock(id);
  const imageName = block?.file.full_size.split('/').slice(5).join('/');

  const {
    data: allFileCopies = {
      blocks: [],
      boards: [],
    },
  } = useQuery({
    queryKey: [id, ALL_FILE_COPIES],
    queryFn: () => getAllCopiesFile(imageName),
    enabled: !!block?.id,
  });

  const { data: boardsSuggestions = [] } = useQuery({
    queryKey: [BOARDS_SUGGESTIONS_QUERY, searchQuery],
    queryFn: () => getBoards({ name: searchQuery }),
    enabled: searchQuery.length > 0,
  });

  const allBoardsIds =
    allFileCopies && allFileCopies.boards.map((board) => board.board_id);

  const emptyList =
    allFileCopies.boards.length === 0 &&
    boardsSuggestions.length === 0 &&
    idsSelectedBoards.length === 0 &&
    searchQuery.length === 0;

  useEffect(() => {
    if (allBoardsIds && allBoardsIds.length > 0) {
      setIdsSelectedBoards(allBoardsIds);
    }
  }, []);

  useEffect(() => {
    if (searchQuery.length === 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchQuery]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchQueryStarted(e.target.value);
  };

  const { mutate: boardMutation } = useMutation({
    mutationFn: (payload: { boardId: string[] }) =>
      bulkClipToBoardImages({
        blocks: [id],
        boards: payload.boardId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [id, ALL_FILE_COPIES],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_SUGGESTIONS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      startedSnack({
        label: 'Added to board',
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: `Couldn't add to board`,
        action: {
          label: 'Try again',
          action: () => {
            bulkClipToBoardImages({
              blocks: [id],
              boards: variables.boardId,
            });
          },
        },
        close: true,
      });
    },
  });

  const { mutate: removeImageFromBoardMutate } = useMutation({
    mutationFn: (data: any) => {
      return bulkDeleteBlock({ block_ids: data.blockId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [id, ALL_FILE_COPIES],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_SUGGESTIONS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });
    },
  });

  const copyOrRemoveBoard = () => {
    if (boardIdsToRemove.blocks.length > 0) {
      removeImageFromBoardMutate({
        blockId: boardIdsToRemove.blocks,
        boardId: boardIdsToRemove.boards,
      });

      setBoardIdsToRemove({
        blocks: [],
        boards: [],
      });
    }

    if (boardIdsToCopy.boards.length > 0) {
      boardMutation({ boardId: boardIdsToCopy.boards });

      setBoardIdsToCopy({
        boards: [],
      });
    }
  };

  return (
    <div className={styles.boardsSearch}>
      <SearchField
        inputRef={inputRef}
        query={searchQuery}
        onChange={onChange}
        emptyList={emptyList}
      />
      <ul className={styles.boardsList}>
        {searchQuery.length === 0 ? (
          <AllBoardsList
            allFileCopies={allFileCopies}
            allBoardsIds={allBoardsIds}
            boardIdsToRemove={boardIdsToRemove}
            setBoardIdsToRemove={setBoardIdsToRemove}
            boardIdsToCopy={boardIdsToCopy}
            setBoardIdsToCopy={setBoardIdsToCopy}
            idsSelectedBoards={idsSelectedBoards}
            setIdsSelectedBoards={setIdsSelectedBoards}
          />
        ) : (
          <SearchBoardsList
            boardsSuggestions={boardsSuggestions}
            allFileCopies={allFileCopies}
            allBoardsIds={allBoardsIds}
            boardIdsToRemove={boardIdsToRemove}
            setBoardIdsToRemove={setBoardIdsToRemove}
            boardIdsToCopy={boardIdsToCopy}
            setBoardIdsToCopy={setBoardIdsToCopy}
            idsSelectedBoards={idsSelectedBoards}
            setIdsSelectedBoards={setIdsSelectedBoards}
          />
        )}
      </ul>
      {(allBoardsIds.length > 0 || idsSelectedBoards?.length > 0) && (
        <div className={styles.actions}>
          <Button
            type="filled"
            label="Save"
            buttonStyle={{ marginRight: '4px' }}
            onClick={copyOrRemoveBoard}
            isDisabled={
              boardIdsToRemove?.boards.length <= 0 &&
              boardIdsToCopy?.boards.length <= 0
            }
          />
        </div>
      )}
    </div>
  );
};
