import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from '@api/designs';

import {
  $isShowBoardDialog,
  $step,
  boardDialogClosed,
  stepContinue,
} from '../../../model';

type Location = {
  id: string;
  level?: number;
  name: string;
  parent?: BoardLvl1 | BoardLvl2 | BoardLvl3 | Hub | null;
  type: 'board' | 'hub';
};

export const searchModalOpened = createEvent<boolean>();
export const searchModalClosed = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const widthSetted = createEvent<number>();

export const locationSelected = createEvent<Location>();
export const locationCleared = createEvent();

// Search modal
export const $isSearchModalOpen = createStore(false);

$isSearchModalOpen
  .on(searchModalOpened, (_, state) => state)
  .reset(searchModalClosed);

// Query
export const $query = createStore('');

$query
  .on(queryEntered, (_, query) => query)
  .reset([queryCleared, searchModalClosed]);

// Location
export const $location = createStore<Location | null>(null);

$location
  .on(locationSelected, (_, location) => location)
  .reset([locationCleared, boardDialogClosed]);

// The width of long names (location) to apply a blur effect
export const $width = createStore(0);

$width.on(widthSetted, (_, width) => width);

// Close the board creation dialog if the hub and board search modal is closed
hotkey({
  key: 'Escape',
  filter: $isShowBoardDialog && $isSearchModalOpen.map((open) => !open),
  target: boardDialogClosed,
});

// Close the hub and board search modal
hotkey({
  key: 'Escape',
  filter: $isSearchModalOpen,
  target: searchModalClosed,
});

// Board creation dialog: On Step 1 - Press Enter to go the next screen
const $goNext = combine(
  $step,
  $isSearchModalOpen,
  (step, isSearchModalOpen) => step === 1 && !isSearchModalOpen,
);

hotkey({
  key: 'Enter',
  filter: $goNext,
  target: stepContinue,
});
