import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { api } from '@api/index';
import { saveAttachmentToLibraryReq } from '@api/messaging';
import { useMutation } from '@tanstack/react-query';

export const useAttachment = () => {
  const history = useHistory();

  const mutateSaveAttachmentToLibrary = useMutation({
    mutationFn: ({
      hubId,
      messageId,
      attachmentId,
    }: {
      hubId: string;
      messageId: string;
      attachmentId: string;
    }) => saveAttachmentToLibraryReq(hubId, messageId, attachmentId),
    onSuccess: (data) => {
      startedSnack({
        label: 'Saved image',
        close: true,
        action: {
          label: 'Go to file',
          action: () => {
            history.push(`/library#/f/${data.id}`);
          },
        },
      });
    },
    onError: (_, { hubId, messageId, attachmentId }) => {
      startedSnack({
        label: 'Couldn’t save image',
        action: {
          label: 'Try again',
          action: () => {
            mutateSaveAttachmentToLibrary.mutate({
              hubId,
              messageId,
              attachmentId,
            });
          },
        },
        close: true,
      });
    },
  });

  const downloadAttachment = async (
    hubId: string,
    messageId: string,
    attachmentId: string,
    attachmentName: string,
  ) => {
    const response = await api.get(
      `/messaging/${hubId}/message/${messageId}/attachment/${attachmentId}/`,
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachmentName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return {
    downloadAttachment,
    mutateSaveAttachmentToLibrary,
  };
};
