import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  IconButton,
  SegmentedButton,
  Switch,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ColorTheme } from '@api/index';
import {
  openedColourPaletteSearch,
  searchedColours,
  toggleSearch,
  updatedColours,
} from '@src/entities/search/model';
import { UseMutateFunction } from '@tanstack/react-query';

import { $openPanel, toggledPanel } from '../model';
import { $palette } from '../palette/model';
import { AdjustPalette } from './adjust-palette';
import { ColorCodes } from './color-codes';
import {
  $isAdjustPalette,
  $isColorCodes,
  adjustPaletteSelected,
  colorCodesSelected,
} from './model/options-managment';

import styles from './styles.module.css';

type Props = {
  fileId: string;
  editColor: UseMutateFunction<AxiosResponse<any>, Error, ColorTheme, unknown>;
};

export const PaletteOptions = ({ fileId, editColor }: Props) => {
  const isAdjustPalette = useUnit($isAdjustPalette);
  const isColorCodes = useUnit($isColorCodes);
  const openPanel = useUnit($openPanel);
  const palette = useUnit($palette);

  const isExtended = openPanel === 'palette';

  const search = () => {
    toggleSearch();

    const selectedColors = palette.swatches.map((item) => ({
      hex: item.hex_color,
      id: item.id,
    }));

    updatedColours(selectedColors);
    openedColourPaletteSearch();
    searchedColours();
  };

  return (
    <div
      className={cn(styles.options, {
        [styles.extended]: isExtended,
      })}
    >
      <div className={styles.header} onClick={() => toggledPanel('palette')}>
        <TypographyPoppins type="title" titleSize="M">
          <span className={styles.title}>Palette options</span>
        </TypographyPoppins>
        <div className={styles.actions}>
          <Switch
            value={isExtended}
            onValueChange={() => toggledPanel('palette')}
          />
          <IconButton
            className={styles.iconButton}
            type="unfilled"
            icon={
              <Icon
                name="sprite/chevron-down"
                className={styles.icon}
                style={{
                  transform: `rotate(${isExtended ? '180deg' : '0deg'})`,
                }}
              />
            }
            onClick={() => {}}
          />
        </div>
      </div>
      <div className={styles.segmentedButtons}>
        <SegmentedButton
          buttonStyle={styles.button}
          isSelected={isAdjustPalette}
          icon={<Icon name="sprite/toggle" />}
          onClick={() => adjustPaletteSelected()}
          isDisabled={!isExtended}
          start
        />
        <SegmentedButton
          buttonStyle={styles.button}
          isSelected={isColorCodes}
          icon={<Icon name="sprite/hashtag" />}
          onClick={() => colorCodesSelected()}
          isDisabled={!isExtended}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/magnifier" />}
          onClick={search}
          isDisabled={!isExtended}
          end
        />
      </div>

      {isAdjustPalette && isExtended && (
        <AdjustPalette fileId={fileId} editColor={editColor} />
      )}
      {isColorCodes && isExtended && <ColorCodes fileId={fileId} />}
    </div>
  );
};
