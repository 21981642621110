import { editColorSwatches } from '@api/blocks';
import { ColorTheme } from '@api/index';
import { IMAGE_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useEditColor = (fileId: string) => {
  const queryClient = useQueryClient();

  const { mutate: editColor } = useMutation({
    mutationFn: (color: ColorTheme) => editColorSwatches(color),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { id: fileId }],
      });
    },
  });

  return { editColor };
};
