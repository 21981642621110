import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router';

import {
  Button,
  Checkbox,
  Divider,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { SimpleAction } from '@api/actions';
import { Spinner } from '@components/Spinner';
import {
  useCreateAction,
  useMutateAction,
} from '@pages/action-items/hooks/useAction';
import { useActions } from '@pages/action-items/hooks/useActions';
import { $actionFilters } from '@pages/action-items/modal';
import { isDateDue } from '@pages/action-items/utils';
import { formatDateString } from '@src/shared/utils/format-date';

import { ActionItemAssignee } from '../Assignee';
import { ActionItemPriority, PriorityType } from '../priority';

import styles from './styles.module.css';

interface ActionItemsProps {
  openDetails: () => void;
}

export const ActionItems = ({ openDetails }: ActionItemsProps) => {
  const [filters] = useUnit([$actionFilters]);
  const { query, actions, isLoading } = useActions({ filters });
  const history = useHistory();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && query.hasNextPage) {
      query.fetchNextPage();
    }
  }, [inView, query.hasNextPage]);

  if (isLoading) {
    return (
      <div className={styles.emptyActionItemsContainer}>
        <div className={styles.emptyContent}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (actions.length == 0)
    return (
      <div className={styles.emptyActionItemsContainer}>
        <div className={styles.emptyContent}>
          <Icon name="sprite/todo-list-soft-colored" size={80} />
          <TypographyPoppins
            type="body"
            bodySize="S"
            style={{ color: 'var(--color-secondary-40)' }}
          >
            Keep track of your to-dos effortlessly
          </TypographyPoppins>
        </div>
        <Button
          label="Add new action item"
          onClick={() => {
            openDetails();
            history.push(`/action-items/new`);
          }}
          type="outlined"
        />
      </div>
    );

  return (
    <ul className={styles.actionItemsListContainer}>
      {actions.map((action) => (
        <ActionItem
          key={action.id}
          action={action}
          onClick={() => {
            history.push(`/action-items/${action.id}`);
            openDetails();
          }}
        />
      ))}
      <DraftActionItem />
      <div ref={ref}></div>
    </ul>
  );
};

export const ActionItem = ({
  action,
  onClick,
  displayDescription = true,
  displayDivider = true,
  dividerAlignAvatar = false,
}: {
  action: SimpleAction;
  onClick: (e: React.MouseEvent) => void;
  displayDescription?: boolean;
  displayDivider?: boolean;
  dividerAlignAvatar?: boolean;
}) => {
  const { mutateAction } = useMutateAction(action.id);

  const updateName = (updatedName: string) => {
    mutateAction.mutate({
      name: updatedName,
    });
  };

  const updatePriority = (priority: PriorityType) => {
    mutateAction.mutate({
      priority,
    });
  };
  const closedBy =
    (action.marked_as_done_by?.first_name ?? '') +
    ' ' +
    (action.marked_as_done_by?.last_name ?? '');
  const closedDate = action.completed_at
    ? formatDateString({
        dateString: action.completed_at,
        displayCurrentYear: false,
      })
    : '';

  return (
    <li style={{ width: '100%' }}>
      <div
        tabIndex={0}
        role="button"
        className={styles.actionItemWrapper}
        onClick={onClick}
      >
        <Tooltip
          parameter={{
            description:
              action.status === 'open'
                ? 'Mark as done'
                : `Closed by ${closedBy} on ${closedDate}`,
            type: 'plain',
            position: 'right',
          }}
          style={{
            minWidth: 'max-content',
          }}
        >
          <Checkbox
            icon={
              <Icon
                name="sprite/checkbox-filled"
                color="var(--color-neutral-variant-30)"
                size={30}
              />
            }
            className={styles.actionItemCheckbox}
            onClick={(e) => {
              e.stopPropagation();
              mutateAction.mutate({
                status: action.status === 'done' ? 'open' : 'done',
              });
            }}
            isSelected={action.status === 'done'}
          />
        </Tooltip>
        <ActionItemContents
          action={action}
          updateName={updateName}
          updatePriority={updatePriority}
          displayDescription={displayDescription}
          displayDivider={displayDivider}
          dividerAlignAvatar={dividerAlignAvatar}
        />
      </div>
    </li>
  );
};

const ActionItemContents = ({
  action,
  updateName,
  updatePriority,
  displayDescription = true,
  displayDivider = true,
  dividerAlignAvatar = false,
}: {
  action: SimpleAction;
  updateName: (s: string) => void;
  updatePriority: (priority: PriorityType) => void;
  displayDescription?: boolean;
  displayDivider?: boolean;
  dividerAlignAvatar?: boolean;
}) => {
  const nameRef = useRef<HTMLDivElement>(null);

  const resetAndSaveEditingState = () => {
    const name = nameRef.current?.textContent?.trim();
    if (name) {
      updateName(name);
      nameRef.current?.blur();
    } else {
      nameRef.current?.focus();
      startedSnack({
        label: `Oops, the name can't be empty`,
        close: true,
      });
    }
  };

  return (
    <div className={styles.actionItemContentsContainer}>
      <div className={styles.actionItemContents}>
        <div className={styles.actionItemMainContent}>
          <div className={styles.actionItemHeader}>
            <TypographyPoppins
              ref={nameRef}
              type="body"
              bodySize="M"
              className={cn(styles.actionItemName, styles.showCursor)}
              contentEditable={true}
              suppressContentEditableWarning
              onBlur={resetAndSaveEditingState}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  resetAndSaveEditingState();
                }
              }}
            >
              {action.name}
            </TypographyPoppins>
            {action.description && displayDescription ? (
              <div className={styles.actionItemDescriptionContainer}>
                <Icon name="sprite/information" size={12} />
                <TypographyPoppins
                  type="body"
                  bodySize="S"
                  className={styles.actionItemDescription}
                >
                  {action.description}
                </TypographyPoppins>
              </div>
            ) : null}
          </div>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={cn(styles.actionItemDate, {
              [styles.dueDate]: action.due_date
                ? isDateDue(action.due_date)
                : false,
            })}
          >
            {action.due_date
              ? formatDateString({
                  dateString: action.due_date,
                  today: true,
                  tomorrow: true,
                  yesterday: true,
                  displayCurrentYear: false,
                })
              : null}
          </TypographyPoppins>
        </div>
        <ActionItemPriority
          priority={action.priority}
          updatePriority={updatePriority}
        />
        <ActionItemAssignee assignees={action.assigned_to} action={action} />
      </div>
      {displayDivider && (
        <Divider
          type="long-line"
          className={cn(styles.divider, {
            [styles.dividerAlignAvatar]: dividerAlignAvatar,
          })}
        />
      )}
    </div>
  );
};

const DraftActionItem = () => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const createAction = useCreateAction();
  const [filters] = useUnit([$actionFilters]);

  const createActionItem = () => {
    const name = nameRef.current?.textContent?.trim();
    if (name && filters.hub && filters.hub.id) {
      createAction.mutate({ name, hub: filters.hub.id });
      if (nameRef.current) {
        nameRef.current.textContent = '';
      }
      setIsEditing(false);
    }
  };
  return (
    <>
      <li>
        <div
          className={styles.actionItemWrapper}
          onClick={() => {
            nameRef.current?.focus();
          }}
        >
          <Checkbox
            icon={
              <Icon
                name="sprite/checkbox-filled"
                color="var(--color-secondary-70)"
                size={30}
              />
            }
            className={cn({
              [styles.draftActionItemCheckbox]: !isEditing,
              [styles.actionItemCheckbox]: isEditing,
            })}
            onClick={() => {}}
            isSelected={false}
          />
          <div className={styles.actionItemContentsContainer}>
            <div className={styles.actionItemContents}>
              <div className={styles.actionItemMainContent}>
                <div className={styles.actionItemHeader}>
                  <TypographyPoppins
                    ref={nameRef}
                    type="body"
                    bodySize="M"
                    className={cn(
                      styles.actionItemName,
                      styles.draftActionItemName,
                      styles.showCursor,
                    )}
                    contentEditable={true}
                    suppressContentEditableWarning
                    onFocus={() => setIsEditing(true)}
                    onBlur={createActionItem}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        createActionItem();
                      }
                    }}
                  >
                    {''}
                  </TypographyPoppins>
                </div>
              </div>
            </div>
            <Divider type="long-line" className={styles.divider} />
          </div>
        </div>
      </li>
    </>
  );
};
