import { editColorSwatches } from '@api/blocks';
import { ColorTheme } from '@api/index';
import {
  allBoardImagesKeys,
  imagesKeys,
  libraryKeys,
} from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useEditColor = (fileId?: string) => {
  const queryClient = useQueryClient();

  const { mutate: editColor, isPending } = useMutation({
    mutationFn: (color: ColorTheme) => editColorSwatches(color),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: libraryKeys.all,
      });

      queryClient.invalidateQueries({
        queryKey: allBoardImagesKeys.all,
      });
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: imagesKeys.image(fileId),
      });
    },
  });

  return { editColor, isPending };
};
