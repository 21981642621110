import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';

import { Details } from './Details';

import styles from './styles.module.css';

export const ShowPlaceholder = () => {
  const { checkoutPro, isPro } = useMembership();

  return (
    <li className={styles.placeholder} id="onboarding-hub-demo">
      <div className={styles.background}>
        <Icon name="sprite/team-soft-colored" size={80} />
        <TypographyPoppins
          type="body"
          bodySize="S"
          className={styles.placeholderLabel}
        >
          Manage projects and clients seamlessly
        </TypographyPoppins>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            className={styles.actionButton}
            type="outlined"
            label="Create a hub"
            isDisabled={isPro === null}
            onClick={isPro ? () => hubDialogOpened() : () => checkoutPro()}
          />
          <Button
            type="ghost"
            label="or learn more"
            onClick={() =>
              window.open(
                'https://www.visualistapp.com/features/client-hubs',
                '_blank',
              )
            }
          />
        </div>
      </div>
      <Details name="New hub" />
    </li>
  );
};
