import chroma from 'chroma-js';
import { createEvent, createStore, sample } from 'effector';

import { ColorSwatch } from '@api/services';

import { $isFileCardOpen } from '../../model';
import {
  $palette,
  dragEnded,
  paletteReseted,
  paletteSelected,
  swatchAdded,
  swatchRemoved,
  swatchUpdated,
} from '../../palette/model';
import {
  adjustPaletteSelected,
  paletteSaved,
} from '../model/options-managment';

export const hueChanged = createEvent<number>();
export const saturationChanged = createEvent<number>();
export const brightnessChanged = createEvent<number>();
export const temperatureChanged = createEvent<number>();

export const $adjustmentParams = createStore({
  hue: 0,
  saturation: 0,
  brightness: 0,
  temperature: 0,
});

$adjustmentParams
  .on(hueChanged, (state, hue) => ({ ...state, hue }))
  .on(saturationChanged, (state, saturation) => ({ ...state, saturation }))
  .on(brightnessChanged, (state, brightness) => ({ ...state, brightness }))
  .on(temperatureChanged, (state, temperature) => ({ ...state, temperature }))
  .reset([paletteReseted, paletteSaved, $isFileCardOpen]);

// A snapshot of the current color shades state, from which Hue, Saturation, Brightness and Temperature are calculated
export const $snapshotCurrentPalette = createStore<{
  theme: string;
  swatches: ColorSwatch[];
}>({ theme: '', swatches: [] });

sample({
  clock: [
    paletteSelected,
    swatchAdded,
    swatchRemoved,
    swatchUpdated,
    dragEnded,
  ],
  source: { palette: $palette },
  fn: ({ palette }) => ({
    theme: palette.theme,
    swatches: palette.swatches,
  }),
  target: $snapshotCurrentPalette,
});

// Adjust Hue, Saturation, Brightness and Temperature
const adjustTemperature = (color: string, temperature: number) => {
  if (temperature === 0) return color;

  // eslint-disable-next-line prefer-const
  let [l, a, b] = chroma(color).lab();

  // Positive values make the color warmer (increase b). Negative values make the color cooler (decrease b)
  const adjustment = temperature * 0.3;

  b += adjustment;

  return chroma.lab(l, a, b).hex();
};

const adjustColor = (
  originalHexColor: string,
  hueAdjustment: number,
  saturationAdjustment: number,
  brightnessAdjustment: number,
  temperatureAdjustment: number,
) => {
  let color = chroma(originalHexColor);
  let [h, s, l] = color.hsl();

  // Adjust Hue
  h = (h + hueAdjustment + 360) % 360;

  // Adjust Saturation
  if (saturationAdjustment < 0) {
    s = s * (1 + saturationAdjustment / 100);
  } else {
    s = s + (1 - s) * (saturationAdjustment / 100);
  }

  s = Math.max(0, Math.min(1, s));

  // Adjust Brightness (Lightness)
  if (brightnessAdjustment < 0) {
    l = l * (1 + brightnessAdjustment / 100);
  } else {
    l = l + (1 - l) * (brightnessAdjustment / 100);
  }

  l = Math.max(0, Math.min(1, l));

  color = chroma.hsl(h, s, l);

  // Adjust Temperature
  if (temperatureAdjustment !== 0) {
    color = chroma(adjustTemperature(color.hex(), temperatureAdjustment));
  }

  const hex_color = color.hex();
  const rgb_color = color.rgb().join(', ');

  const hsl_color = `${Math.round(color.hsl()[0] || 0)}, ${Math.round(
    (color.hsl()[1] || 0) * 100,
  )}%, ${Math.round((color.hsl()[2] || 0) * 100)}%`;

  const hsv_color = `${Math.round(color.hsv()[0] || 0)}, ${Math.round(
    (color.hsv()[1] || 0) * 100,
  )}%, ${Math.round((color.hsv()[2] || 0) * 100)}%`;

  return {
    hex_color,
    rgb_color,
    hsl_color,
    hsv_color,
  };
};

// Hue
sample({
  clock: hueChanged,
  source: { palette: $snapshotCurrentPalette, adjustments: $adjustmentParams },
  fn: ({ palette, adjustments }) => ({
    theme: palette.theme,
    swatches: palette.swatches.map((swatch) => {
      const adjustedColor = adjustColor(
        swatch.hex_color,
        adjustments.hue,
        adjustments.saturation,
        adjustments.brightness,
        adjustments.temperature,
      );
      return {
        ...swatch,
        ...adjustedColor,
      };
    }),
  }),
  target: [$palette],
});

// Saturation
sample({
  clock: saturationChanged,
  source: { palette: $snapshotCurrentPalette, adjustments: $adjustmentParams },
  fn: ({ palette, adjustments }) => ({
    theme: palette.theme,
    swatches: palette.swatches.map((swatch) => {
      const adjustedColor = adjustColor(
        swatch.hex_color,
        adjustments.hue,
        adjustments.saturation,
        adjustments.brightness,
        adjustments.temperature,
      );
      return {
        ...swatch,
        ...adjustedColor,
      };
    }),
  }),
  target: $palette,
});

// Brightness
sample({
  clock: brightnessChanged,
  source: { palette: $snapshotCurrentPalette, adjustments: $adjustmentParams },
  fn: ({ palette, adjustments }) => ({
    theme: palette.theme,
    swatches: palette.swatches.map((swatch) => {
      const adjustedColor = adjustColor(
        swatch.hex_color,
        adjustments.hue,
        adjustments.saturation,
        adjustments.brightness,
        adjustments.temperature,
      );
      return {
        ...swatch,
        ...adjustedColor,
      };
    }),
  }),
  target: $palette,
});

sample({
  clock: $isFileCardOpen,
  filter: (isFileCardOpen) => !isFileCardOpen,
  target: [paletteReseted, adjustPaletteSelected],
});

// Temperature
sample({
  clock: temperatureChanged,
  source: { palette: $snapshotCurrentPalette, adjustments: $adjustmentParams },
  fn: ({ palette, adjustments }) => ({
    theme: palette.theme,
    swatches: palette.swatches.map((swatch) => {
      const adjustedColor = adjustColor(
        swatch.hex_color,
        adjustments.hue,
        adjustments.saturation,
        adjustments.brightness,
        adjustments.temperature,
      );
      return {
        ...swatch,
        ...adjustedColor,
      };
    }),
  }),
  target: $palette,
});
