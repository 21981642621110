import { useEffect, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { isMobile } from 'react-device-detect';

import {
  Button,
  FilterChip,
  TextField,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { Title } from '@src/entities/dialogs/ui/title';

import {
  $boardDescription,
  $boardName,
  $step,
  boardDescriptionCleared,
  boardDescriptionEntered,
  boardDialogClosed,
  boardNameCleared,
  boardNameEntered,
  stepBack,
} from '../../model';
import { Search } from '../search';
import { useSearch } from '../search/useSearch';

import styles from './styles.module.css';

export const Step2 = () => {
  const step = useUnit($step);
  const boardName = useUnit($boardName);
  const boardDescription = useUnit($boardDescription);

  const textField = useRef<HTMLInputElement | null>(null);

  const {
    ref,
    location,
    width,
    getLeadingIcon,
    getTrailingIcon,
    clear,
    isShowTooltip,
  } = useSearch();

  const { makeBoard } = useBoards({});

  const createBoard = () => {
    const hub = location && location.type === 'hub' ? location.id : undefined;
    const parent =
      location && location.type === 'board' ? location.id : undefined;

    if (boardName.trim()) {
      makeBoard({
        name: boardName,
        description: boardDescription,
        hub,
        parent,
      });

      boardDialogClosed();
    }
  };

  useEffect(() => {
    // Focus password element after animation is complete otherwise is janks
    let timeout: NodeJS.Timeout | null = null;

    if (step === 2) {
      timeout = setTimeout(() => {
        textField.current?.focus();
      }, 300);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [step]);

  return (
    <>
      <div className={styles.progress}>
        <button className={styles.back} onClick={() => stepBack()}></button>
        <button className={styles.current}></button>
      </div>
      <Title className={styles.title} title="Create a board" />
      {location && (
        <Search>
          {isShowTooltip() ? (
            <Tooltip
              parameter={{
                description: <Breadcrumbs board={location.parent} />,
                type: 'plain',
                position: 'top',
              }}
              style={{
                maxWidth: '230px',
                width: 'max-content',
                minWidth: 'min-content',
              }}
            >
              <FilterChip
                ref={ref}
                className={cn({
                  [styles.filterChip]: location,
                })}
                classNameLabel={cn({
                  [styles.blur]: width >= 302,
                })}
                type="label"
                labelSize="L"
                leadingIcon={getLeadingIcon()}
                trailingIcon={getTrailingIcon()}
                trailingAction={clear}
                isSelected={Boolean(location.name)}
              >
                {location.name}
              </FilterChip>
            </Tooltip>
          ) : (
            <FilterChip
              ref={ref}
              className={cn({
                [styles.filterChip]: location,
              })}
              classNameLabel={cn({
                [styles.blur]: width >= 302,
              })}
              type="label"
              labelSize="L"
              leadingIcon={getLeadingIcon()}
              trailingIcon={getTrailingIcon()}
              trailingAction={clear}
              isSelected={Boolean(location.name)}
            >
              {location.name}
            </FilterChip>
          )}
        </Search>
      )}
      <div className={styles.info}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          Board name
        </TypographyPoppins>
        <TextField
          ref={textField}
          value={boardName}
          onChange={(e) => boardNameEntered(e.target.value)}
          clear={() => boardNameCleared()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              createBoard();
            }
          }}
          tabIndex={1}
        />
      </div>
      <div className={styles.info}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          Description (optional)
        </TypographyPoppins>
        <div className={styles.textarea}>
          <textarea
            className={styles.description}
            value={boardDescription}
            onChange={(e) => boardDescriptionEntered(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                e.preventDefault();
                if (boardDescription.trim()) {
                  createBoard();
                }
              }
            }}
            tabIndex={2}
          />
          {boardDescription.trim() && (
            <button
              className={styles.clear}
              onClick={() => boardDescriptionCleared()}
              type="button"
            >
              <Icon name="sprite/x" size={16} />
            </button>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          type="outlined"
          label="Cancel"
          onClick={() => boardDialogClosed()}
          tabIndex={4}
        />
        <Button
          type="filled"
          label="Create"
          onClick={createBoard}
          isDisabled={boardName.trim().length === 0}
          tabIndex={3}
        />
      </div>
    </>
  );
};
