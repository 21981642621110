import { getHub } from '@api/hubs';
import { ContainingHub } from '@src/entities/messages/ui/containing-hub';
import { HUB_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { EmailList } from '../email-list';

import styles from './styles.module.css';

export const EmailsSidebar = ({ hubId }: { hubId?: string }) => {
  const {
    data: hub,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [HUB_QUERY, { hubId: hubId }],
    queryFn: () => getHub({ hubId: hubId }),
  });

  if (!hubId) {
    return (
      <aside className={styles.emailsSidebar}>
        <ContainingHub hub={hub} />
      </aside>
    );
  }

  if (isLoading) {
    return (
      <aside className={styles.emailsSidebar}>
        <p>Loading...</p>
      </aside>
    );
  }

  if (isError) {
    return (
      <aside className={styles.emailsSidebar}>
        <p>Error: {error.message}</p>
      </aside>
    );
  }

  if (!hub) {
    return (
      <aside className={styles.emailsSidebar}>
        <p>No Hub data recieved</p>
      </aside>
    );
  }

  return (
    <aside className={styles.emailsSidebar}>
      <ContainingHub hub={hub} />
      <EmailList hubId={hubId} />
    </aside>
  );
};
