import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';

type DueFilter = 'today' | 'tomorrow' | 'upcoming' | 'expired' | '';

type OrderingFilter = 'created_at' | 'due_date' | 'priority' | '';
type SortOrder = 'ascending' | 'descending';

type StatusFilter = 'open' | 'done' | '';

type FilterByHub = 'all' | 'hub';

export type Hub = {
  id: string | null;
  name?: string;
} | null;

export type ActionFilter = {
  ordering: OrderingFilter;
  sortOrder: SortOrder;
  due: DueFilter;
  status: StatusFilter;
  hub: Hub;
  filterByHub: FilterByHub;
};

export const $actionFilters = createStore<ActionFilter>({
  ordering: 'created_at',
  sortOrder: 'descending',
  due: '',
  status: 'open',
  hub: null,
  filterByHub: 'all',
});

export const updatedDueFilter = createEvent<DueFilter>();
export const updatedOrderingFilter = createEvent<OrderingFilter>();
export const toggleStatusFilter = createEvent<StatusFilter>();
export const updatedHubFilter = createEvent<Hub>();
export const updatedSortOrder = createEvent<SortOrder>();
export const updatedFilterByHub = createEvent<FilterByHub>();

$actionFilters
  .on(updatedDueFilter, (state, due) => ({
    ...state,
    due,
  }))
  .on(updatedOrderingFilter, (state, ordering) => ({
    ...state,
    ordering,
  }))
  .on(toggleStatusFilter, (state, status) => ({
    ...state,
    status,
  }))
  .on(updatedHubFilter, (state, hub) => ({
    ...state,
    hub,
  }))
  .on(updatedSortOrder, (state, sortOrder) => ({
    ...state,
    sortOrder,
  }))
  .on(updatedFilterByHub, (state, filterByHub) => ({
    ...state,
    filterByHub,
  }));

persist({ store: $actionFilters, key: 'action-list-filters' });
