import { createEvent, createStore, sample } from 'effector';

import { ColorTheme } from '@api/services';

// Activation of custom theme creation
export const themeCreationEnabled = createEvent();
export const themeCreationCompleted = createEvent();

export const $isThemeCreation = createStore(false);

$isThemeCreation
  .on(themeCreationEnabled, () => true)
  .reset(themeCreationCompleted);

// Theme name
export const themeRenamingActivated = createEvent();
export const themeRenamingDeactivated = createEvent();

export const $isRenamingTheme = createStore(false);

$isRenamingTheme
  .on(themeRenamingActivated, () => true)
  .reset(themeRenamingDeactivated);

export const themeNameChanged = createEvent<string>();
export const themeNameCleared = createEvent();

export const $themeName = createStore('');

$themeName.on(themeNameChanged, (_, name) => name).reset(themeNameCleared);

// Placeholder for creating a custom theme
export const themePlaceholderCleared = createEvent();

const initialState: ColorTheme[] = [
  {
    theme: 'Custom',
    type: 'custom_palette',
    swatches: [
      {
        hex_color: '',
        hsl_color: '',
        hsv_color: '',
        id: 'empty-1',
        order: 1,
        rgb_color: '',
        theme: '',
      },
      {
        hex_color: '',
        hsl_color: '',
        hsv_color: '',
        id: 'empty-2',
        order: 2,
        rgb_color: '',
        theme: '',
      },
      {
        hex_color: '',
        hsl_color: '',
        hsv_color: '',
        id: 'empty-3',
        order: 3,
        rgb_color: '',
        theme: '',
      },
      {
        hex_color: '',
        hsl_color: '',
        hsv_color: '',
        id: 'empty-4',
        order: 4,
        rgb_color: '',
        theme: '',
      },
      {
        hex_color: '',
        hsl_color: '',
        hsv_color: '',
        id: 'empty-5',
        order: 5,
        rgb_color: '',
        theme: '',
      },
    ],
  },
];

export const $themePlaceholder = createStore<ColorTheme[]>(initialState);

$themePlaceholder.reset(themePlaceholderCleared);

// Adding color swatches
export const swatchAdded = createEvent<{
  order: number;
  rgb_color: string;
  hex_color: string;
}>();

sample({
  clock: swatchAdded,
  source: $themePlaceholder,
  fn: (items, { order, rgb_color, hex_color }) =>
    items.map((item) => ({
      ...item,
      swatches: item.swatches.map((swatch) =>
        swatch.order === order ? { ...swatch, rgb_color, hex_color } : swatch,
      ),
    })),
  target: $themePlaceholder,
});

// Removing color swatches
export const swatchRemoved = createEvent<number>();

sample({
  clock: swatchRemoved,
  source: $themePlaceholder,
  fn: (items, orderToRemove) =>
    items.map((item) => ({
      ...item,
      swatches: item.swatches.map((swatch) =>
        swatch.order === orderToRemove
          ? {
              hex_color: '',
              hsl_color: '',
              hsv_color: '',
              id: `empty-${swatch.order}`,
              order: swatch.order,
              rgb_color: '',
              theme: item.theme,
            }
          : swatch,
      ),
    })),
  target: $themePlaceholder,
});

// The state for the server request that contains only filled swatches
export const $filledSwatches = $themePlaceholder.map((items) =>
  items.map((item) => ({
    ...item,
    swatches: item.swatches.filter((swatch) => swatch.hex_color),
  })),
);
