import { SimpleAction } from '@api/actions';
import { getLatestActionFromConversation } from '@api/vai';
import { vaiGeneratedActionKeys } from '@src/shared/constants/query-keys';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useActionConversation = (props?: { hubId?: string }) => {
  return useInfiniteQuery({
    queryKey: vaiGeneratedActionKeys.all,
    queryFn: ({ pageParam }) => {
      const offset = parseInt(pageParam) ?? 0;
      return getLatestActionFromConversation({
        offset,
        limit: 1,
        hubId: props?.hubId,
      });
    },
    initialPageParam: '0',
    getNextPageParam: (lastGroup) => {
      return (
        lastGroup.next && new URL(lastGroup.next).searchParams.get('offset')
      );
    },
    select: (data) => {
      return {
        ...data,
        actions: data.pages.reduce(
          (acc, item) => [...acc, ...item.results].reverse(),
          [] as Array<SimpleAction>,
        ),
      };
    },
  });
};
