import { motion, MotionProps } from 'framer-motion';
import { useHistory } from 'react-router';

import { ColourSearchBlock } from '@api/blocks';

import { hideSearch } from '../../model';

import styles from './styles.module.css';

export const ColourResult = ({
  block,
  ...rest
}: MotionProps & {
  block: ColourSearchBlock;
}) => {
  const history = useHistory();

  const openFileCard = () => {
    hideSearch();
    history.push(`/library#/f/${block.id}`);
  };

  return (
    <motion.button
      {...rest}
      onClick={openFileCard}
      className={styles.container}
    >
      <img
        loading="lazy"
        className={styles.image}
        src={block.file.full_size}
        alt="Search result"
      />
      <div className={styles.colours}>
        {block.colors.slice(0, 5).map((c, idx, arr) => (
          <div
            key={c.hex_color}
            className={styles.colour}
            style={
              {
                backgroundColor: c.hex_color,
                '--idx': arr.length - idx,
                border: `1px solid var(--color-neutral-variant-50)`,
              } as React.CSSProperties
            }
          ></div>
        ))}
      </div>
    </motion.button>
  );
};
