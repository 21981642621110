import {
  IconButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { SMALL } from '@src/shared/constants/breakpoints';

import { searchBoardsModalWindowOpened } from '../model';

import styles from './styles.module.css';

export const CopyFileOtherBoard = () => {
  const { width } = useWindowSize();

  return (
    <Tooltip
      style={{
        minWidth: 'max-content',
        top: width <= SMALL ? '-5px' : '24px',
        marginLeft: width <= SMALL ? '85px' : '0px',
      }}
      parameter={{
        type: 'plain',
        description: 'Copy file to other boards',
        position: width <= SMALL ? 'top' : 'left',
      }}
    >
      <div className={styles.board}>
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          onClick={() => searchBoardsModalWindowOpened()}
        />
      </div>
    </Tooltip>
  );
};
