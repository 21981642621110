import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Spinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getHub, getHubs } from '@api/hubs';
import { ErrorBoundary } from '@sentry/react';
import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { HubSearch } from '@src/entities/search-modal/hub-search';
import { ALL_HUBS_QUERY, HUB_QUERY } from '@src/shared/constants';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';
import { useQuery } from '@tanstack/react-query';

import { EmailViewer } from './ui/email-view/email-viewer';
import { MessagesDocumentHead } from './ui/messages-document-head';
import { EmailsSidebar } from './ui/side-bar/emails-sidebar';

import styles from './styles.module.css';

export const MessagesPage = () => {
  const params = useParams<{ hubId?: string; threadId?: string }>();

  const hubsQuery = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  // Loading hubs
  if (hubsQuery.isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  // No hub ID in params
  if (!params.hubId) {
    // Check if user has hubs
    if (hubsQuery.data && hubsQuery.data.length) {
      return <SelectHub />;
    } else return <NoHub />;
  }

  return <MessagesWithHubId hubId={params.hubId} threadId={params.threadId} />;
};

const MessagesWithHubId = ({
  hubId,
  threadId,
}: {
  hubId: string;
  threadId?: string;
}) => {
  const history = useHistory();

  const hubQuery = useQuery({
    queryKey: [HUB_QUERY, { hubId: hubId }, 'specific'],
    queryFn: () => getHub({ hubId: hubId }),
  });

  if (hubQuery.isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  if (hubQuery.isError) {
    return <SelectHub />;
  }

  return (
    <div className={styles.container}>
      <MessagesDocumentHead hubId={hubId ?? undefined} />
      <SearchDialog hideMobileSearch />
      <div
        className={cn(styles.messagesContainer)}
        // Only applies on mobile sizes
        style={
          {
            '--grid-template-areas': threadId
              ? '"email-viewer"'
              : '"emails-sidebar"',
          } as React.CSSProperties
        }
      >
        <ErrorBoundary
          fallback={
            <div className={styles.error}>
              <TypographyPoppins type="body" bodySize="M">
                Oops, something went wrong.
              </TypographyPoppins>
              <Button
                type="filled"
                label="Go to home"
                onClick={() => {
                  history.push('/home');
                }}
              />
            </div>
          }
        >
          <EmailsSidebar hubId={hubId} />
          <EmailViewer hubId={hubId} threadId={threadId} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

const NoHub = () => {
  const { checkoutPro, isPro } = useMembership();

  return (
    <div className={styles.emptyContainer}>
      <div className={styles.emptyTop}>
        <Icon size={80} name="sprite/lamp-and-chair-colored" />
        <TypographyPoppins type="body" bodySize="S" className={styles.text}>
          Your message center is waiting.
          <br />
          <br />
          Create a hub to unlock your dedicated message
          <br />
          center and keep communications tidy within
          <br />
          each project.
        </TypographyPoppins>
      </div>
      <Button
        label="Create a hub"
        isDisabled={isPro === null}
        onClick={isPro ? () => hubDialogOpened() : () => checkoutPro()}
        type="outlined"
      />
    </div>
  );
};

const SelectHub = () => {
  const { checkoutPro, isPro } = useMembership();
  const history = useHistory();

  return (
    <div className={styles.emptyContainer}>
      <div className={styles.emptyTop}>
        <Icon size={80} name="sprite/paper-plane-with-shape-colored" />
        <TypographyPoppins type="body" bodySize="S" className={styles.text}>
          Oops, this page doesn’t exist.
          <br />
          <br />
          Each hub has a dedicated message center.
          <br />
          Pick one of your hubs to see messages in it.
        </TypographyPoppins>
      </div>
      <div className={styles.selectOrCreate}>
        <HubSearch
          onResultClick={(h) => {
            history.push(`/messages/${h.id}`);
          }}
          selectedHub={null}
        >
          <Button label="Select a hub" onClick={() => {}} type="outlined" />
        </HubSearch>
        <Button
          label="or create new hub"
          isDisabled={isPro === null}
          onClick={isPro ? () => hubDialogOpened() : () => checkoutPro()}
          type="ghost"
          labelSize="S"
        />
      </div>
    </div>
  );
};
