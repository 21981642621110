import { PropsWithChildren, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';
import { useHistory, useLocation } from 'react-router';

import {
  Dropdown,
  IconButton,
  NavItemList,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { createNewSet } from '@api/blocks';
import { Board, getBoards } from '@api/boards';
import { ResponseCreateDoc } from '@api/docs';
import { getHubs, HubResponse } from '@api/hubs';
import { LAST_OPENED_MESSAGE_HUB } from '@pages/messages/constants';
import { useOpenedDesigns } from '@pages/StudioPage/hooks/useOpenedDesigns';
import { openedLibrary } from '@pages/StudioPage/model';
import {
  deleteBoardModalOpened,
  deleteHubModalOpened,
} from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';
import {
  hubDescriptionEntered,
  hubIdSelected,
  hubNameEntered,
  hubRenamingDialogOpened,
  locationEntered,
} from '@src/entities/dialogs/hub/model';
import { useCreateDoc } from '@src/entities/doc/queries/useCreateDoc';
import { Tab } from '@src/entities/doc/ui/tabs';
import { toggleSearch } from '@src/entities/search/model';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import { boardsKeys } from '@src/shared/constants/query-keys';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  Sidebar,
  SidebarCollapsibleItem,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarNavItems,
  SidebarSpacer,
  SidebarUser,
} from '..';
import { $isExpanded, expandedSidebar, shrunkSidebar } from '../model';
import { openVaiModal } from '../open-vai';
import { VaiButton } from '../ui/vai';

import styles from './styles.module.css';

const SIDEBAR_WIDTH = 64;
const SIDEBAR_EXPANDED_WIDTH = 170;
const SIDEBAR_DEFAULT_WIDTH = 220;
const SIDEBAR_MAX_WIDTH = 400;

export const MainSidebar = ({ children }: PropsWithChildren) => {
  const [isSidebarExpanded] = useUnit([$isExpanded]);
  const { width } = useWindowSize();
  const isMobile = width < 487;
  const { pathname } = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarPanelRef = useRef<ImperativePanelHandle>(null);

  const history = useHistory();

  const { loadOpenedDesigns } = useOpenedDesigns();

  const { mutate: createNewSetMutation } = useMutation({
    mutationFn: () => createNewSet({ name: 'Untitled' }),
    onSuccess: async ({ data }) => {
      history.push(`/studio/${data.id}`);
      setTimeout(() => {
        openedLibrary();
      }, 500);
    },
  });

  const goToStudio = async () => {
    if (pathname.includes('studio')) return;

    try {
      const designs = await loadOpenedDesigns();

      if (designs.size > 0) {
        const design = designs.values().next().value;

        if (!design) {
          createNewSetMutation();
        } else {
          history.push(`/studio/${design.id}`);
          setTimeout(() => {
            openedLibrary();
          }, 500);
        }
      } else {
        createNewSetMutation();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const [lastOpenedMessageHub, setLastOpenedMessageHub] = useLocalStorage(
    LAST_OPENED_MESSAGE_HUB,
    hubs.length ? hubs[0].id : undefined,
  );

  const [tabs, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);
  const { makeDocMutation } = useCreateDoc();

  const storeNewDoc = (doc: ResponseCreateDoc) => {
    setTabs((tabs) => [
      ...tabs,
      {
        id: doc.id,
        label: doc.title,
        onClick: () => {},
      },
    ]);
  };

  const goToDoc = async () => {
    if (/^\/d\//.test(pathname)) return;

    try {
      if (tabs.length > 0) {
        const docTab = tabs[0];
        history.push(`/d/${docTab.id}`);
      } else {
        const newDoc = await makeDocMutation.mutateAsync({});
        storeNewDoc(newDoc);
        history.push(`/d/${newDoc.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const goToMessageCenter = () => {
    if (lastOpenedMessageHub) {
      // If last opened hub is on local storage use that
      history.push(`/messages/${lastOpenedMessageHub}`);
    } else {
      if (hubs.length > 0) {
        // Use first hub result from api
        setLastOpenedMessageHub(hubs[0].id);
        history.push(`/messages/${hubs[0].id}`);
      } else {
        history.push('/messages');
      }
    }
  };

  if (pathname.includes('/account/')) return <>{children}</>;

  if (isMobile) {
    return (
      <>
        <Sidebar>
          <SidebarNavItems>
            <SidebarItem
              leftIcon={<Icon name="sprite/studio" />}
              name="Studio"
              action={{ type: 'click', onClick: goToStudio }}
              isSelected={pathname.includes('/studio')}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/doc" />}
              name="Docs"
              action={{ type: 'click', onClick: goToDoc }}
              isSelected={pathname.includes('/d/')}
            />
            <SidebarDivider />
            <SidebarCollapsibleItem
              name="Hubs"
              leftIcon={<Icon name="sprite/hub" />}
              expandSidebar={() => expandedSidebar()}
            >
              <Hubs />
            </SidebarCollapsibleItem>
            <SidebarDivider />
            <SidebarCollapsibleItem
              name="Library"
              leftIcon={<Icon name="sprite/library" />}
              expandSidebar={() => expandedSidebar()}
            >
              <SidebarItem
                leftIcon={<Icon name="sprite/grid" />}
                name="Files"
                action={{ type: 'link', to: '/library' }}
                isSelected={pathname.includes('/library')}
                isNested
              />
              <SidebarCollapsibleItem
                name="Boards"
                leftIcon={<Icon name="sprite/board" />}
                isNested
                expandSidebar={() => expandedSidebar()}
              >
                <BoardsNotInHubs />
              </SidebarCollapsibleItem>
            </SidebarCollapsibleItem>
          </SidebarNavItems>
          <SidebarFooter />
        </Sidebar>
        {children}
      </>
    );
  }

  const minSizePercentage = (SIDEBAR_EXPANDED_WIDTH / width) * 100;
  const defaultPercentage = (SIDEBAR_DEFAULT_WIDTH / width) * 100;
  const maxSizePercentage = (SIDEBAR_MAX_WIDTH / width) * 100;
  const collapsedPercentage = (SIDEBAR_WIDTH / width) * 100;

  return (
    <PanelGroup autoSaveId="persistence" direction="horizontal">
      <Panel
        style={{
          overflow: 'visible',
        }}
        ref={sidebarPanelRef}
        collapsible={true}
        collapsedSize={collapsedPercentage}
        defaultSize={defaultPercentage}
        minSize={minSizePercentage}
        maxSize={maxSizePercentage}
        onCollapse={() => shrunkSidebar()}
        onExpand={() => expandedSidebar()}
      >
        <Sidebar ref={sidebarRef}>
          <SidebarHeader
            onClick={() => {
              if (isSidebarExpanded) {
                shrunkSidebar();
                sidebarPanelRef.current?.collapse();
              } else {
                expandedSidebar();
                sidebarPanelRef.current?.expand();
              }
            }}
          />
          <SidebarSpacer />
          <SidebarUser />
          <SidebarSpacer height={28} />
          <SidebarNavItems>
            <SidebarItem
              leftIcon={<Icon name="sprite/home" />}
              name="Home"
              action={{ type: 'link', to: '/home' }}
              isSelected={pathname.includes('/home')}
            />
            <SidebarItem
              leftIcon={<VaiButton />}
              name="Vai"
              action={{
                type: 'click',
                onClick: () => openVaiModal({ pathname, hubs }),
              }}
              isSelected={false}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/magnifier" />}
              name="Search"
              alternativeSelected
              action={{ type: 'click', onClick: () => toggleSearch() }}
              isSelected={false}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/studio" />}
              name="Studio"
              action={{ type: 'click', onClick: goToStudio }}
              isSelected={pathname.includes('/studio')}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/doc" />}
              name="Docs"
              action={{ type: 'click', onClick: goToDoc }}
              isSelected={pathname.includes('/d/')}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/paper-plane" />}
              name="Messages"
              action={{ type: 'click', onClick: goToMessageCenter }}
              isSelected={pathname.includes('/messages')}
            />
            <SidebarItem
              leftIcon={<Icon name="sprite/action-items-list" />}
              name="Action items"
              action={{ type: 'link', to: '/action-items' }}
              isSelected={pathname.includes('/action-items')}
            />
            <SidebarDivider />
            <SidebarCollapsibleItem
              name="Hubs"
              leftIcon={<Icon name="sprite/hub" />}
              expandSidebar={() => {
                expandedSidebar();
                sidebarPanelRef.current?.expand();
              }}
            >
              <Hubs />
            </SidebarCollapsibleItem>
            <SidebarDivider />
            <SidebarCollapsibleItem
              name="Library"
              leftIcon={<Icon name="sprite/library" />}
              expandSidebar={() => {
                expandedSidebar();
                sidebarPanelRef.current?.expand();
              }}
            >
              <SidebarItem
                leftIcon={<Icon name="sprite/grid" />}
                name="Files"
                action={{ type: 'link', to: '/library' }}
                isSelected={pathname.includes('/library')}
                isNested
              />
              <SidebarCollapsibleItem
                name="Boards"
                leftIcon={<Icon name="sprite/board" />}
                isNested
                expandSidebar={() => {
                  expandedSidebar();
                  sidebarPanelRef.current?.expand();
                }}
              >
                <BoardsNotInHubs />
              </SidebarCollapsibleItem>
            </SidebarCollapsibleItem>
          </SidebarNavItems>
          <SidebarFooter />
        </Sidebar>
      </Panel>
      <PanelResizeHandle className={styles.handle} />
      <Panel>{children}</Panel>
    </PanelGroup>
  );
};

const Hubs = () => {
  const history = useHistory();
  const location = useLocation();

  const [openedHubDropdownId, setOpenedHubDropdownId] = useState('');
  const [openedBoardDropdownId, setOpenedBoardDropdownId] = useState('');

  const { data: hubs } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const { data: boards } = useQuery({
    queryKey: ['boards_tree'],
    queryFn: () => getBoards({ view_type: 'tree' }),
  });

  const boardsWithRightAccess = boards?.length
    ? boards?.filter((board) => board.permissions.length > 0)
    : [];

  const hubToBoardMapping = boardsWithRightAccess?.reduce((dict, board) => {
    // Find boards with parent hubs
    if (board.hub) {
      if (dict[board.hub.id]) {
        // Array already created
        dict[board.hub.id].push(board);
      } else {
        // No array yet
        dict[board.hub.id] = [board];
      }
    }

    return dict;
  }, {} as Record<string, Board[]>);

  const openHub = (hubId: string) => {
    history.push(`/h/${hubId}`);
  };

  const openBoard = (boardId: string) => {
    history.push(`/b/${boardId}`);
  };

  const openedDropdownHub = (id: string) => {
    setOpenedHubDropdownId(id);
  };

  const openedDropdownBoard = (id: string) => {
    setOpenedBoardDropdownId(id);
  };

  if (!hubs || hubs.length === 0) return null;

  return (
    <>
      {hubs.map((hub) => {
        return (
          <NavItemList
            key={hub.id}
            indent={0}
            name={hub.name}
            trailingIcon={
              <HubDropdown
                hub={hub}
                openedHubDropdownId={openedHubDropdownId}
                openedDropdownHub={openedDropdownHub}
              />
            }
            iconAfterText={hub.share ? <Icon name="sprite/public" /> : null}
            onClick={() => openHub(hub.id)}
            onContextMenu={(e) => {
              e.preventDefault();
              openedDropdownHub(hub.id);
            }}
            isSelected={location.pathname.includes(hub.id)}
            isHoveredOverride={openedHubDropdownId === hub.id}
          >
            {hubToBoardMapping && hubToBoardMapping[hub.id]
              ? hubToBoardMapping[hub.id].map((b) => {
                  return (
                    <NavItemList
                      key={b.id}
                      name={b.name}
                      indent={1}
                      leadingIcon={<Icon name="sprite/board" />}
                      trailingIcon={
                        <BoardDropdown
                          board={b}
                          openedBoardDropdownId={openedBoardDropdownId}
                          openedDropdownBoard={openedDropdownBoard}
                        />
                      }
                      iconAfterText={
                        b.is_shared ? <Icon name="sprite/public" /> : null
                      }
                      onClick={() => openBoard(b.id)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        openedDropdownBoard(b.id);
                      }}
                      isSelected={location.pathname.includes(b.id)}
                      isHoveredOverride={openedBoardDropdownId === b.id}
                    >
                      {b.children && b.children.length
                        ? b.children.map((b1) => {
                            return (
                              <NavItemList
                                key={b1.id}
                                name={b1.name}
                                indent={2}
                                leadingIcon={<Icon name="sprite/board" />}
                                trailingIcon={
                                  <BoardDropdown
                                    board={b1}
                                    openedBoardDropdownId={
                                      openedBoardDropdownId
                                    }
                                    openedDropdownBoard={openedDropdownBoard}
                                  />
                                }
                                iconAfterText={
                                  b1.is_shared ? (
                                    <Icon name="sprite/public" />
                                  ) : null
                                }
                                onClick={() => openBoard(b1.id)}
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  openedDropdownBoard(b1.id);
                                }}
                                isSelected={location.pathname.includes(b1.id)}
                                isHoveredOverride={
                                  openedBoardDropdownId === b1.id
                                }
                              >
                                {b1.children && b1.children.length
                                  ? b1.children.map((b2) => {
                                      return (
                                        <NavItemList
                                          key={b2.id}
                                          name={b2.name}
                                          indent={3}
                                          leadingIcon={
                                            <Icon name="sprite/board" />
                                          }
                                          trailingIcon={
                                            <BoardDropdown
                                              board={b2}
                                              openedBoardDropdownId={
                                                openedBoardDropdownId
                                              }
                                              openedDropdownBoard={
                                                openedDropdownBoard
                                              }
                                            />
                                          }
                                          iconAfterText={
                                            b2.is_shared ? (
                                              <Icon name="sprite/public" />
                                            ) : null
                                          }
                                          onClick={() => openBoard(b2.id)}
                                          onContextMenu={(e) => {
                                            e.preventDefault();
                                            openedDropdownBoard(b2.id);
                                          }}
                                          isSelected={location.pathname.includes(
                                            b2.id,
                                          )}
                                          isHoveredOverride={
                                            openedBoardDropdownId === b2.id
                                          }
                                        />
                                      );
                                    })
                                  : null}
                              </NavItemList>
                            );
                          })
                        : null}
                    </NavItemList>
                  );
                })
              : null}
          </NavItemList>
        );
      })}
    </>
  );
};

const HubDropdown = ({
  hub,
  openedHubDropdownId,
  openedDropdownHub,
}: {
  hub: HubResponse;
  openedHubDropdownId: string;
  openedDropdownHub: (id: string) => void;
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 487;

  const copyHubLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/h/${hub.id}`,
      );

      startedSnack({
        label: 'Copied link to hub',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to hub",
        action: {
          label: 'Try again',
          action: () => {
            copyHubLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    hubRenamingDialogOpened();
    hubNameEntered(hub.name);
    hubIdSelected(hub.id);

    if (hub.description) {
      hubDescriptionEntered(hub.description);
    }

    if (hub.project_details?.location) {
      locationEntered(hub.project_details?.location);
    }
  };

  return (
    <Dropdown
      open={openedHubDropdownId === hub.id}
      onOpenChange={(openState) => {
        if (openState) {
          openedDropdownHub(hub.id);
        } else {
          openedDropdownHub('');
        }
      }}
    >
      <Dropdown.Menu
        trigger={
          <IconButton
            onClick={() => {}}
            type="unfilled"
            className={cn(styles.dropdownButton, {
              [styles.selected]: openedHubDropdownId === hub.id,
            })}
            icon={<Icon size={12} name="sprite/3-dot-menu" />}
            style={{
              opacity: isMobile ? '1' : undefined,
            }}
            isSelected={openedHubDropdownId === hub.id}
          />
        }
        side="right"
        alignOffset={-10}
        sideOffset={20}
        density="-2"
        align="start"
      >
        <Dropdown.MenuItem
          item={{
            content: 'Copy link',
            leadingIcon: <Icon name="sprite/copy" />,
            onClick: copyHubLink,
          }}
        />
        <Dropdown.MenuItem
          item={{
            content: 'Rename',
            leadingIcon: <Icon name="sprite/pen" />,
            isDivider: true,
            onClick: rename,
          }}
        />
        <Dropdown.MenuItem
          item={{
            content: 'Delete',
            leadingIcon: <Icon color="var(--color-error)" name="sprite/bin" />,
            classNameContent: styles.red,
            onClick: () => {
              // TODO Refactor board dialogs here for deleting and creating a board
              deleteHubModalOpened({
                hubId: hub.id,
                name: hub.name,
              });
            },
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const BoardDropdown = ({
  board,
  openedBoardDropdownId,
  openedDropdownBoard,
}: {
  board: Board;
  openedBoardDropdownId: string;
  openedDropdownBoard: (id: string) => void;
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 487;

  const copyBoardLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/b/${board.id}`,
      );

      startedSnack({
        label: 'Copied link to board',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyBoardLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(board.name);
    boardIdSelected(board.id);

    if (board.description) {
      boardDescriptionEntered(board.description);
    }
  };

  return (
    <Dropdown
      open={openedBoardDropdownId === board.id}
      onOpenChange={(state) => {
        if (state) {
          openedDropdownBoard(board.id);
        } else {
          openedDropdownBoard('');
        }
      }}
    >
      <Dropdown.Menu
        trigger={
          <IconButton
            onClick={() => {}}
            type="unfilled"
            className={cn(styles.dropdownButton, {
              [styles.selected]: openedBoardDropdownId === board.id,
            })}
            icon={<Icon size={12} name="sprite/3-dot-menu" />}
            style={{
              opacity: isMobile ? '1' : undefined,
            }}
            isSelected={openedBoardDropdownId === board.id}
          />
        }
        alignOffset={-10}
        sideOffset={20}
        side="right"
        density="-2"
        align="start"
      >
        <Dropdown.MenuItem
          item={{
            content: 'Copy link',
            leadingIcon: <Icon name="sprite/copy" />,
            onClick: copyBoardLink,
          }}
        />
        <Dropdown.MenuItem
          item={{
            content: 'Rename',
            leadingIcon: <Icon name="sprite/pen" />,
            isDivider: true,
            onClick: rename,
          }}
        />
        <Dropdown.MenuItem
          item={{
            content: 'Delete',
            leadingIcon: <Icon color="var(--color-error)" name="sprite/bin" />,
            classNameContent: styles.red,
            onClick: () => {
              deleteBoardModalOpened({
                boardId: board.id,
                hubId: board.hub?.id,
                parentBoardId: board.parent?.id,
              });
            },
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const BoardsNotInHubs = () => {
  const history = useHistory();
  const location = useLocation();

  const [openedBoardDropdownId, setOpenedBoardDropdownId] = useState('');

  const { data: boards } = useQuery({
    queryKey: boardsKeys.noHub(),
    queryFn: () => getBoards({ view_type: 'tree', hub: 'no_hub' }),
  });

  const boardsWithRightAccess = boards?.length
    ? boards?.filter((board) => board.permissions.length > 0)
    : [];

  const boardsOutsideOfHubs = boardsWithRightAccess?.filter((b) => {
    return !b.hub;
  });

  const openBoard = (boardId: string) => {
    history.push(`/b/${boardId}`);
  };

  const openedDropdownBoard = (id: string) => {
    setOpenedBoardDropdownId(id);
  };

  if (!boardsOutsideOfHubs) return null;

  return (
    <>
      {boardsOutsideOfHubs.map((b) => {
        return (
          <NavItemList
            key={b.id}
            name={b.name}
            indent={0}
            trailingIcon={
              <BoardDropdown
                board={b}
                openedBoardDropdownId={openedBoardDropdownId}
                openedDropdownBoard={openedDropdownBoard}
              />
            }
            onClick={() => openBoard(b.id)}
            onContextMenu={(e) => {
              e.preventDefault();
              openedDropdownBoard(b.id);
            }}
            isSelected={location.pathname.includes(b.id)}
            isHoveredOverride={openedBoardDropdownId === b.id}
          >
            {b.children && b.children.length
              ? b.children.map((b1) => {
                  return (
                    <NavItemList
                      key={b1.id}
                      name={b1.name}
                      indent={1}
                      leadingIcon={<Icon name="sprite/board" />}
                      trailingIcon={
                        <BoardDropdown
                          board={b1}
                          openedBoardDropdownId={openedBoardDropdownId}
                          openedDropdownBoard={openedDropdownBoard}
                        />
                      }
                      onClick={() => openBoard(b1.id)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        openedDropdownBoard(b1.id);
                      }}
                      isSelected={location.pathname.includes(b1.id)}
                      isHoveredOverride={openedBoardDropdownId === b1.id}
                    >
                      {b1.children && b1.children.length
                        ? b1.children.map((b2) => {
                            return (
                              <NavItemList
                                key={b2.id}
                                name={b2.name}
                                indent={2}
                                leadingIcon={<Icon name="sprite/board" />}
                                trailingIcon={
                                  <BoardDropdown
                                    board={b2}
                                    openedBoardDropdownId={
                                      openedBoardDropdownId
                                    }
                                    openedDropdownBoard={openedDropdownBoard}
                                  />
                                }
                                onClick={() => openBoard(b2.id)}
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  openedDropdownBoard(b2.id);
                                }}
                                isSelected={location.pathname.includes(b2.id)}
                                isHoveredOverride={
                                  openedBoardDropdownId === b2.id
                                }
                              />
                            );
                          })
                        : null}
                    </NavItemList>
                  );
                })
              : null}
          </NavItemList>
        );
      })}
    </>
  );
};
