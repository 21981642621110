import { useState } from 'react';

import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { ErrorBoundary } from '@sentry/react';
import { ActionItemDetails } from '@src/entities/sheet/action-item-details';

import { ActionItemsHeader } from './ui/action-items-header';
import { ActionItems } from './ui/action-items-list';

import styles from './styles.module.css';

export const ActionItemsPage = () => {
  const history = useHistory();
  const { actionId } = useParams<{
    actionId?: string;
  }>();
  const [showActionItemDetails, setShowActionItemDetails] = useState<boolean>(
    actionId ? true : false,
  );
  const openActionItemDetails = () => {
    setShowActionItemDetails(true);
  };

  return (
    <div className={styles.container}>
      <DocumentHead name="Action items" />
      <div className={styles.actionItemsContainer}>
        <ErrorBoundary
          fallback={
            <div className={styles.error}>
              <TypographyPoppins type="body" bodySize="M">
                Oops, something went wrong.
              </TypographyPoppins>
              <Button
                type="filled"
                label="Go to home"
                onClick={() => {
                  history.push('/home');
                }}
              />
            </div>
          }
        >
          <div
            className={cn(styles.actionItemListContainer, {
              [styles.actionItemSheetOpen]: showActionItemDetails,
            })}
          >
            <ActionItemsHeader createActionItem={openActionItemDetails} />
            <ActionItems openDetails={openActionItemDetails} />
          </div>
          <ActionItemDetails
            isOpen={showActionItemDetails}
            closeAction={() => {
              history.push('/action-items');
              setShowActionItemDetails(false);
            }}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};
