import { ResponseDoc } from '@api/docs';
import { HubResponse } from '@api/hubs';
import { Block, Board, ImageTypes } from '@api/index';
import { ImageBlock } from '@pages/StudioPage/api';
import { StickyType } from '@src/entities/Stickies/types';

import { api, BlockResponse } from '.';

// type MatchedField<T extends Record<string, any>> = T & {
//   matching_field: T;
// };

type SearchSpecific = {
  similarity: number;
  search_type: 'content' | 'name';
  matched_field: string;
};

type StickySearchBlock = {
  id: string;
  name: string;
  file: ImageTypes;
  boards: Array<{
    id: string;
    name: string;
  }> | null;
};

export type PossibleResult =
  | (Pick<ResponseDoc, 'id' | 'title' | 'board'> &
      SearchSpecific & { type: 'doc' })
  | (Pick<Board, 'id' | 'name' | 'recent_images' | 'parent'> &
      SearchSpecific &
      ResultType<'board'>)
  | (Pick<HubResponse, 'id' | 'thumbnail' | 'name'> &
      SearchSpecific &
      ResultType<'hub'>)
  | (Pick<Block, 'id' | 'name' | 'file' | 'boards'> &
      SearchSpecific &
      ResultType<'image'>)
  | (Pick<BlockResponse, 'id' | 'name' | 'file' | 'boards'> &
      SearchSpecific &
      ResultType<'palette'>)
  | (Pick<ImageBlock, 'id' | 'name' | 'file' | 'boards'> &
      SearchSpecific &
      ResultType<'design'>)
  | (Pick<StickyType, 'id' | 'text' | 'parent'> & {
      block: StickySearchBlock[];
    } & SearchSpecific &
      ResultType<'stickies' | 'commentSticker'>);

type ResultType<T extends SearchType> = {
  type: T;
};

export type SearchType =
  | 'doc'
  | 'hub'
  | 'board'
  | 'image'
  | 'design'
  | 'palette'
  | 'stickies'
  | 'commentSticker';

type SearchProps = {
  query: string;
  type?: SearchType[];
};

export const searchExactMatch = async ({
  query,
  type = [],
}: SearchProps): Promise<Array<PossibleResult>> => {
  const { data } = await api.post('/search/', {
    query: query.trim(),
    type,
  });

  return data;
};
